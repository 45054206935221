import { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import Slider from "react-slick";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import InsightsIcon from "@mui/icons-material/Insights";
import BarChartIcon from "@mui/icons-material/BarChart";
import PolicyIcon from "@mui/icons-material/Policy";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
const settingsAboutSlider3 = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        dots: true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ],
  slidesToShow: 9,
};

export const Industries = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* Hero Section  */}
      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>Project and Enterprise Pricing</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            More than just ESG reporting software
          </h2>
          <p>
            Pricing is designed to be transparent and economical, by focusing on
            the amount of value we deliver by solving your unique set of needs.
            Reach out to build your own custom quote and see the value that is
            included with your subscription below.
          </p>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper report-slider ">
        <div className="container-new about-new-section testimonial  report-testi ">
          <div
            className="about-cards-section servicecards"
            style={{ padding: 0, position: "relative" }}
          >
            <Slider {...settingsAboutSlider3}>
              <div className="about-card">
                <h4 className="before">5 Hours</h4>
                <h3 className="after">20 Minutes</h3>
                <p>
                  Median time to complete monthly reporting in SocialPro vs
                  spreadsheet returnable systems.
                </p>
              </div>
              <div className="about-card">
                <Diversity3OutlinedIcon />
                <h3>Social Procurement</h3>
                <p>
                  Measure performance against prescribed social outcomes,
                  relating to participation and procurement priorities.
                </p>
              </div>
              <div className="about-card">
                <ForestOutlinedIcon />
                <h3>Sustainability</h3>
                <p>
                  Track and report on sustainability metrics like fuel, water,
                  waste and materials usage, helping your projects meet
                  environmental goals.
                </p>
              </div>
              <div className="about-card">
                <PlaceIcon />
                <h3>Local Content</h3>
                <p>
                  Easily report on locally manufactured and supplied content,
                  demonstrating your commitment to supporting local suppliers
                  and meeting project requirements.
                </p>
              </div>
              <div className="about-card">
                <DescriptionIcon />
                <h3>Stakeholder Reporting</h3>
                <p>
                  Generate comprehensive and bespoke reports for clients and
                  government stakeholders, showcasing your project's performance
                  and compliance with reporting obligations.
                </p>
              </div>
              <div className="about-card">
                <QueryStatsIcon />
                <h3>Company Performance</h3>
                <p>
                  Monitor and assess your company's performance across multiple
                  projects, to measure and report impact created at the
                  organisational level.
                </p>
              </div>
              <div className="about-card">
                <InsightsIcon />
                <h3>Analytics & Insights</h3>
                <p>
                  Gain valuable insights into project performance with real-time
                  analytics across your supply chain, helping you make informed
                  decisions and achieve your targets efficiently.
                </p>
              </div>
              <div className="about-card">
                <BarChartIcon />
                <h3>Project Forecasting</h3>
                <p>
                  Predict and plan for project outcomes with our forecasting
                  tools, allowing you to map out hours, spend, and
                  sustainability usage against your program for better project
                  management.
                </p>
              </div>
              <div className="about-card">
                <PolicyIcon />
                <h3>Smart Directory</h3>
                <p>
                  Connect with suppliers that can support your contractual
                  obligations through our Smart Directory, promoting impactful
                  partnerships and fulfilling social and sustainability targets.
                </p>
              </div>
            </Slider>
          </div>
          <div className="zigzag zigzag-form">
            <div className="zig-content">
              <div className="zig-inner-content">
                <h4>Get a custom quote</h4>
                <h3>Fill out this pricing request form</h3>
                <p>
                  You wouldn't place a tender without first understanding the
                  full scope of work. We approach custom quotes the same way.
                  Help us get to know your team's needs to get started now.{" "}
                </p>
              </div>
            </div>
            <div className="zig-img zig-form">
              <div className="contact-form-new">
                <form action="" className="pricing-form">
                  <h4>About your company or project.</h4>
                  <label htmlFor="company_select">Type of Company*</label>

                  <select name="company_select" id="company_select">
                    <option value="Client">Client</option>
                    <option value="Main Contractor">Main Contractor</option>
                    <option value="Subcontractor">Subcontractor</option>
                    <option value="Local/Social Benefit Supplier">
                      Local/Social Benefit Supplier
                    </option>
                  </select>

                  <label htmlFor="licence_select">Type of licence*</label>
                  <select name="licence_select" id="licence_select">
                    <option value="Enterprise">Enterprise</option>
                    <option value="Project Only (Less than $20m)">
                      Project Only (Less than $20m)
                    </option>
                    <option value="Project Only ($20-50m)">
                      Project Only ($20-50m)
                    </option>
                    <option value="Project Only ($50-250m)">
                      Project Only ($50-250m)
                    </option>
                    <option value="Project Only ($250m +)">
                      Project Only ($250m +)
                    </option>
                  </select>
                  <div className="checkbox-div">
                    <label htmlFor="" className="checkbox-label">
                      What are you looking to improve?
                    </label>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Social procurement reporting"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Sustainability reporting"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Local content reporting"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Stakeholder reporting"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Insights into company performance"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Project forecasting"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Industry directory"
                      />
                      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                    </FormGroup>
                  </div>
                  <h4>Where to send your quote?</h4>
                  <div className="inputs-cont">
                    <div className="input-wrap">
                      <label htmlFor="first_name">First Name</label>
                      <input type="text" id="first_name" />
                    </div>
                    <div className="input-wrap">
                      <label htmlFor="last_name">Last Name</label>
                      <input type="text" id="last_name" />
                    </div>
                    <div className="input-wrap">
                      <label htmlFor="email">Email</label>
                      <input type="email" id="email" />
                    </div>
                    <div className="input-wrap">
                      <label htmlFor="phone">Phone</label>
                      <input type="number" id="phone" />
                    </div>
                    <div className="input-wrap">
                      <label htmlFor="company_name">Company Name</label>
                      <input type="text" id="company_name" />
                    </div>
                    <div className="input-wrap">
                      <label htmlFor="role">Role</label>
                      <input type="text" id="role" />
                    </div>
                  </div>
                  <p>
                    SocialPro needs the contact information you provide to us to
                    contact you about our products and services. You may
                    unsubscribe from these communications at any time by
                    clicking the unsubscribe link at the bottom of emails sent
                    us. For information on our privacy practices and commitment
                    to protecting your privacy, please review our Privacy
                    Policy.
                  </p>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

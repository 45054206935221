import { useEffect } from "react";
import {Footer} from './Footer';
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const PrivacyPolicy = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>Legal</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            Privacy Policy
          </h2>
          <p><b>2024</b></p>
        </div>
      </div>

      {/* text Section  */}
      <div className="definitions-section">
        <div className="container-new">
          <div className="def-txt" data-aos="fade-up" data-aos-duration="1000">
            <p>
            At SocialPro, we take data and its collection seriously, always working to ensure local laws are followed, our customers personal information is stored correctly nor distributed or sold outside the terms set below. If you have any questions about this Policy, including any requests to exercise your legal rights, please contact us by email at <a href="mailto:support@socialproapp.com">support@socialproapp.com</a>.
            </p>

            <p>
            We are strongly committed to the transparency of data collection and its appropriate use before, during and after obtaining your personal information. The policies outlined below are applicable to data and information collected through SocialPro and their related entities, whether that be obtained through their network of websites including socialproapp.com (including any optimised versions for mobile tablets or wireless viewing); any and all email newsletters published or distributed by SocialPro; all apps published by SocialPro, including the ‘SocialPro’ Web-App; or use any other services made available by SocialPro (“Service”) and all other interactive features and communications provided by SocialPro (“Platform”), however accessed and/or used, that are operated by us, made available by us, or produced and maintained by us and our related companies (collectively “SocialPro Software Pty Ltd” or “SocialPro", "we”, “us”, or “our”).

            </p>

            <p>
              We have established this privacy policy (“Privacy Policy”) to let
              you know the kinds of personal information we may gather during
              your use of this App, why we gather your information, what we use
              your personal information for, when we might disclose your
              personal information, and how you can manage your personal
              information.{" "}
            </p>

            <p>
            We have established this privacy policy (“Privacy Policy”) to let you know the kinds of personal information we may gather during your use of this Platform, why we gather your information, what we use your personal information for, when we might disclose your personal information, and how you can manage your personal information.

            </p>

            <p>
            Please be advised that the practices described in this Privacy Policy apply to information gathered online through our Platform, through our websites and otherwise by our customer service personnel. It does not apply to information that you may submit to organisations to which we may link or who may link to us or information that we may receive about you from other organisations.
            </p>

            <p>
            If you are using our service, you are accepting the practices described in our Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Platform. We reserve the right to modify or amend the terms of our Privacy Policy from time to time without notice. Your continued use of our Platform following the posting of changes to these terms will mean you accept those changes. If we intend to apply the modifications or amendments to this Privacy Policy retroactively or to personal information already in our possession, we will provide you with notice of the modifications or amendments.
            </p>
            <p>
             If you have any questions about this Privacy Policy or don’t see your concerns addressed here, please contact us by email at <a href="mailto:support@socialproapp.com">support@socialproapp.com</a>.
             This policy is effective from 1st December 2023.       
            </p>

            <h4>Hosting</h4>

            <p>
            Our Server hosts, AWS, are located in Australia. As is true of most websites, we gather certain information automatically through your use of the Site. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring or exit pages, the files viewed on the Site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and clickstream data to analyze trends in the aggregate and administer the site, this data is saved into server logs. For more information, please see AWS’s privacy policy.

            </p>

            <h4>Google Analytics</h4>

            <p>
            When someone visits socialproapp.com we use a third party service, Google Analytics, to collect standard internet log information and details of visitor behaviour patterns. We do this to find out things such as the number of visitors to the various parts of the site. This information does not identify anyone and we don’t use any method that would allow ourselves or Google to work out the identities of those visiting our website. If we do want to collect personally identifiable information through our website, we will be up front about this. We will make it clear when we collect personal information and will explain what we intend to do with it.

            </p>

            <h4>What we Collect</h4>
            <p>We may collect the following information:</p>
            <ul>
              <li>Name, job title and address; </li>
              <li>
              Contact information including email address;
              </li>
              <li>Demographic information such as postcode, preferences and interests;</li>
              <li>Other information relevant to customer surveys and/or offers.</li>
            </ul>

            <p>If you contact us about a trade account then we will also collect details about your business and your role in it for example, if you are a partnership we will require details about your business interest and may also require details about your business partners. To open an account you will also need to provide us with financial information such as bank account details and credit history information and will also be required to provide us with details of trade referees. This is all information which we may retain on our records for future reference.
            </p>
            <p>If you contact us through this website (including if you complete a contact form), by telephone, email or if you write to us, the content of those communications may also include personal data and we may also keep a copy of that correspondence on our records.
            </p>
            <p>If you provide us with information about any other person, for example any other individuals within your organisation or your business partners, then you must ensure that you have consent to pass those details to us and for us to use those details in accordance with this privacy policy.  You should also make sure that you bring this privacy policy to their attention.</p>

            <h4>What we do with the information we gather</h4>
            <p>
            We require this information for the proper administration of our business, to understand your needs and to provide you with a better service, and in particular for the following reasons:
            </p>
  

            <ul>
              <li>
              Account management and dealing with invoicing arrangements;
              </li>
              <li>
              Internal record keeping;
              </li>
              <li>
              We may use the information to improve our products and services;
              </li>
              <li>
              We may periodically send promotional email about new products, special offers or other information which we think you may find interesting using the email address which you have provided;
              </li>
              <li>From time to time, we may also use your information to contact you 
for market research purposes. We may contact you by email, phone, or mail.
We may use the information to customise the website according to your interests.</li>
            </ul>

            <p>If at any time you wish to stop receiving the type of information referred to in (d) and (e) above then you can contact us at any time, using the contact details shown below.
            </p>
            <p>We might also use your personal details to deal with any enquiries or complaints which you may raise or if you report any problems with our site and to contact you and deal with those issues as appropriate.</p>
              <p>We may also use your information for other business purposes such as market research activities that we may undertake on occasion.  This may also involve the collection of survey information, involving opinions on our products or may include technical information such as Internet Protocol (IP) address information used to connect your computer to the Internet or traffic data about visits to our website and the products searched for or viewed.
               </p>
            <h4>Disclosure of data to third parties </h4>

            <p>
            From time to time we might also appoint external third parties to help us with our business functions for example, external consultants and sub-contractors.  These might include delivery contractors, marketing consultants or companies who help us to manage our website.  We therefore, may make your data available to those entities if reasonable to do so as part of our usual business practices.  When we make your personal data available to such third parties for those purposes, we will only make available to them the data that they need to perform their obligations to us.

            </p>
            <p>If you are an account holder and customer, we will disclose your data to third party credit reference agencies and to any trade referees who you may refer us to so that we can take up appropriate references and perform necessary checks.  We will keep the details of such checks on our records and may also disclose those details to third parties for the purpose of ongoing risk assessment.</p>
            <p>We will never disclose any personal data to a third party that has been directly inputted into your organisation login instance of SocialPro, i.e. any personal beneficiary and/or survey data or any other data you may directly enter into our Platform.</p>
            <h4>
            Data breaches
            </h4>

            <p>
            In the event that we discover or suspect a data breach we will notify you as soon as we are able to provide sufficient information as to the nature and extent of the breach. The notice will include, where available at the time of sending, the following:
            </p>
            <ul>
              <li>The dates and times of the security event</li>
              <li>The details of the event including:</li>
              <li>A description of the data involved in the event</li>
              <li>The facts of the event or details of the decision to investigate a suspected event</li>
              <li>The steps we are taking or planning to take to remedy or mitigate the vulnerability.</li>
            </ul>

            <h4>Links to other websites</h4>

            <p>
            Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.

            </p>

            <h4>
            Controlling your personal information
            </h4>

              <p>
              If you do not wish us to collect and use your personal data as set out in this privacy policy then you should refrain from sending any such information to us.  By providing information to us, you will be taken to have given your consent to the use of your personal information in accordance with this privacy policy.
              You may instruct us not to process your personal data for marketing purposes by email at any time.
              If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
              </p>

            <h4>Our rights</h4>

            <p>
            We may disclose information about you to any of our employees, officers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes as set out in this privacy policy.
            In addition, we may disclose information about you:
            </p>
              <ul>
              <li>To the extent that we are required to do so by law;</li>
              <li>In connection with any legal proceedings or prospective legal proceedings;</li>
              <li>In order to establish, exercise or defend our legal rights (including providing information to others for the purposes of 
              fraud prevention and reducing credit risk);</li>
              <li>To the purchaser (or prospective purchaser) of any business or asset which we are (or are contemplating) selling.</li>
              </ul>

            <p>
            We may also monitor user and network traffic for site security purposes and may use any information, including personal data to prevent any unauthorised attempts to tamper with our website or cause damage to our property.  We also reserve our rights to disclose your personal information either where you have given us explicit consent to do so or otherwise where we may do so in accordance with applicable data protection legislation.
            Except as provided in this privacy policy, we will not provide your information to third parties.
            </p>

            <h4>Security of your personal data</h4>
            <p>We are committed to ensuring that your information is secure. We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information including putting in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
           </p>
           <p>We will store all the personal information you provide on our secure (password- and firewall- protected) servers. All electronic transactions you make to or receive from us will be encrypted using SSL technology.
          </p>
          <p>However, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
          </p>
          <p>You are responsible for keeping your password and user details confidential. We will not ask you for your password at any time.
          </p>

           <h4>Cookies</h4>
            <p>
            This website uses cookies.  You can find out more information about cookies and the way in which we use them in our Cookies Policy which you can access Here

            </p>
            <h4>Changes to this policy</h4>

            <p>
            We may update this privacy policy from time-to-time by posting a new version on our website.  You should check this page occasionally to ensure you are happy with any changes.

           We may also notify you of changes to our privacy policy by email.
            </p>
            <h4>Severability</h4>

            <p>
            If a provision of this Privacy Policy is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.  If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.
            </p>

          <h4>Contact</h4>

          <p>If you have any questions about this privacy policy or our treatment of your personal data, please write to us by email to <a href="mailto:support@socialproapp.com">support@socialproapp.com</a>.
          </p>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
    <Footer />
    </>
  );
};

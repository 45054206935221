import { BasicLayout } from "@stories/layout/BasicLayout";
import * as RouteHelper from "@utils/routes";
import { AboutsView } from "@views/Pages/Home/AboutsView";
import { ApiIntegrationView } from "@views/Pages/Home/ApiIntegrationView";
import { BlogDetail } from "@views/Pages/Home/BlogDetails";
import { BlogView } from "@views/Pages/Home/BlogView";
import { DefinitionsView } from "@views/Pages/Home/DefinitionsView";
import { DemoView } from "@views/Pages/Home/DemoView";
import { HomeView } from "@views/Pages/Home/HomeView";
import { ImplementationView } from "@views/Pages/Home/ImplementationView";
import { IndustriesView } from "@views/Pages/Home/IndustriesView";
import { ManageView } from "@views/Pages/Home/ManageView";
import { MeasureView } from "@views/Pages/Home/MeasureView";
import { OurImpactView } from "@views/Pages/Home/OurImpact";
import { PartnerView } from "@views/Pages/Home/PartnerView";
import { PrivacyPolicyView } from "@views/Pages/Home/PrivacyPolicyView";
import { ReportingAndAnalyticsView } from "@views/Pages/Home/ReportingAndAnalyticsView";
import { ReportView } from "@views/Pages/Home/ReportView";
import { SmartDirectoryView } from "@views/Pages/Home/SmartDirectoryView";
import { SupportView } from "@views/Pages/Home/SupportView";
import { TermsConditionsView } from "@views/Pages/Home/TermsConditionsView";
import { WhoWeAreView } from "@views/Pages/Home/WhoWeAreView";

export interface RouteInfo {
  index?: boolean;
  path: string;
  component: React.FC<any>;
  layout: React.FC<any>;
}

const routesUnauthenticated: RouteInfo[] = [
  {
    component: HomeView,
    index: true,
    layout: BasicLayout,
    path: RouteHelper.home,
  },
  {
    component: DemoView,
    layout: BasicLayout,
    path: RouteHelper.demo,
  },
  {
    component: OurImpactView,
    layout: BasicLayout,
    path: RouteHelper.ourImpact,
  },
  {
    component: AboutsView,
    layout: BasicLayout,
    path: RouteHelper.abouts,
  },
  {
    component: SmartDirectoryView,
    layout: BasicLayout,
    path: RouteHelper.smartDirectory,
  },
  {
    component: ApiIntegrationView,
    layout: BasicLayout,
    path: RouteHelper.apiIntegration,
  },
  {
    component: ImplementationView,
    layout: BasicLayout,
    path: RouteHelper.implementation,
  },
  {
    component: BlogView,
    layout: BasicLayout,
    path: RouteHelper.blog,
  },
  {
    component: BlogDetail,
    layout: BasicLayout,
    path: RouteHelper.blogDetail(":title"),
  },
  {
    component: DefinitionsView,
    layout: BasicLayout,
    path: RouteHelper.definitions,
  },
  {
    component: WhoWeAreView,
    layout: BasicLayout,
    path: RouteHelper.whoWeare,
  },
  {
    component: SupportView,
    layout: BasicLayout,
    path: RouteHelper.support,
  },
  {
    component: ReportingAndAnalyticsView,
    layout: BasicLayout,
    path: RouteHelper.reportingAndAnalytics,
  },
  {
    component: PrivacyPolicyView,
    layout: BasicLayout,
    path: RouteHelper.privacyPolicy,
  },
  {
    component: TermsConditionsView,
    layout: BasicLayout,
    path: RouteHelper.termsConditions,
  },
  {
    component: ManageView,
    layout: BasicLayout,
    path: RouteHelper.manage,
  },
  {
    component: MeasureView,
    layout: BasicLayout,
    path: RouteHelper.measure,
  },
  {
    component: ReportView,
    layout: BasicLayout,
    path: RouteHelper.report,
  },
  {
    component: IndustriesView,
    layout: BasicLayout,
    path: RouteHelper.industries,
  },
  {
    component: PartnerView,
    layout: BasicLayout,
    path: RouteHelper.projectForecasting,
  },
];

export const getRoutes = () => {
  return routesUnauthenticated;
};

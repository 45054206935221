import { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import arrowbtn from "assets/images/home/arrowBtn.png";
import team from "assets/images/home/about-team.png";
export const Abouts = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />

       {/* secondary Hero section */}
       <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>About SocialPro</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
          Software built with a purpose
          </h2>
          <p>
          Develop and deploy cloud-based technological solutions tailored to the unique needs of operators, both contractors and subcontractors, of major projects, to highlight social procurement outcomes and the need to engage with social benefit suppliers.
          </p>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
      <div className="container-new">
            <div className="zigzag about-us">
              <div className="zig-content">
                <div className="zig-inner-content">
                  <h4>Our team</h4>
                  <h3>
                  Making a difference.
                  </h3>
                  <p>
                  Driven by a shared vision, we developed a cloud-based solution to address the challenges faced by the construction industry in managing their social procurement contributions. Our mission is to empower businesses of all sizes in the construction industry to streamline their procurement processes, enhance transparency, and make a meaningful impact toward social and sustainability goals. With our passion for creating a more equitable future, we are committed to making a difference by reshaping participation and procurement in the construction industry.
                  </p>
                  <p>Our dedicated team brings together expertise from social procurement, government policy, construction and corporate reporting. This diverse blend of industry backgrounds has enabled us to recognise a significant gap in the market and an opportunity for transformative change and drive impact. Motivated by our collective desire to make a positive difference, we set out to revolutionise the way construction businesses approach procurement.
                  </p>
                  <div className="btns-wrap hero-btns">
                    {/* <a href="/measure" className="btn btn-grey">
                      Discover more
                    </a> */}
                    <a
                      href="/request-demo"
                      className="discover"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      Request a demo
                      <img src={arrowbtn} alt="arrowbtn" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="zig-img">
                <img src={team} alt="" />
              </div>
            </div>
          </div>
      </div>
      {/* Footer Section  */}
      <Footer />
    </>
  );
};

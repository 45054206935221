import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import instgram2 from "assets/images/icons/instagram.png";
import linkedin2 from "assets/images/icons/linkedin.png";
import { Header } from "./Header";
import {Footer} from './Footer';
import "./style.css";
import socialLinks from "./BlogData";

export default function () {
  const { title } = useParams();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [blogData] = useState(
    socialLinks.find((item) => {
      return (
        item.title.toLowerCase() === title?.replace(/[-]/g, " ")?.toLowerCase()
      );
    })
  );

  return (
    <>
      {/* Header Section  */}
      <Header />

      {/* Blog section */}
      <div className=" blog-section">
        <div className="container-new">
          <div
            className=" blog-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {blogData && (
              <>
                <h3>NEWS & ARTICLES</h3>
                <h1>{blogData.title}</h1>

                <h4>
                  By {blogData.author} on {blogData.publish}
                </h4>

                <div className="blog-socials">
                  <span>Social Share:</span>{" "}
                  <a
                    href="https://www.instagram.com/socialpro.app/"
                    target="_blank"
                  >
                    <img src={instgram2} alt="instgram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/socialpro-app/"
                    target="_blank"
                  >
                    <img src={linkedin2} alt="linkedin" />
                  </a>
                </div>
                <div className="blog-det-img">
                  <blogData.img />
                </div>
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: blogData.text }}
                    className="text"
                  />
                }
              </>
            )}
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
}

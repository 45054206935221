import { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import arrowbtn from "assets/images/home/arrowBtn.png";
import socialtraders from "assets/images/home/social-traders.png";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
export const OurImpact = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />
      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>Our Capabilities</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            Empowerment through technology
          </h2>
          <p>
            Develop and deploy cloud-based technological solutions tailored to
            the unique needs of operators, both contractors and subcontractors,
            of major projects, to highlight social procurement outcomes and the
            need to engage with social benefit suppliers.{" "}
          </p>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
        <div className="container-new">
          <div className="servicecards cardswrapper capability-cards ">
            <div className="about-card">
              <AutoFixHighOutlinedIcon />
              <h3>Continuous Innovation</h3>
              <p>
                Remain at the forefront of innovation within the industry by
                refining our cloud-based reporting tool and complementing with
                powerful features to maximise efficiencies for users, to
                continue driving outcomes for social benefit suppliers.
              </p>
            </div>
            <div className="about-card">
              <BackHandOutlinedIcon />
              <h3>Advocacy and Awareness</h3>
              <p>
                Champion the cause of our mission by engaging in advocacy
                efforts and raising awareness about the capabilities of social
                benefit suppliers to contribute to major projects, fostering
                broader understanding and support.
              </p>
            </div>
            <div className="about-card">
              <Diversity3OutlinedIcon />
              <h3>Community Impact</h3>
              <p>
                Facilitate collaboration and partnerships that amplify the
                positive contribution of social, disability, and indigenous
                businesses on major projects, contributing to sustainable
                economic growth.
              </p>
            </div>
          </div>
          <div className="zigzag ourimpact">
            <div className="zig-content">
              <div className="zig-inner-content">
                <h4>Social Traders</h4>
                <h3>Generating Social Value Above & Beyond</h3>
                <p>
                  SocialPro is a recognised Victorian social enterprise, as
                  certified by Social Traders. By unleashing the power of social
                  enterprise, Social Traders creates positive impact through
                  jobs, community services and support for the most
                  marginalised.
                </p>

                <div className="btns-wrap hero-btns">
                  <a
                    href="/request-demo"
                    className="discover"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    Request a demo
                    <img src={arrowbtn} alt="arrowbtn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="zig-img">
              <img src={socialtraders} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};


import { useEffect } from "react";
import hero from "assets/images/home/Local Content.png";
import arrowbtn from "assets/images/home/arrowBtn.png";
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const WhoWeAre = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />
     {/* Hero Section  */}
    <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Local Content</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Local supply chain reporting
            </h3>
            <p>
            Easily report on locally manufactured and supplied content, demonstrating your commitment to supporting local suppliers and meeting project requirements. </p>   
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
           
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
         <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <SummarizeIcon/>
              <h3>Meet Project Requirements</h3>
              <p>
              Ensure compliance with project requirements for local procurement with reporting on local construction content items against project targets.</p>
            </div>
            <div className="about-card">
            <LocationOnIcon/>
             <h3>Support Local Industry</h3>
              <p>
              Demonstrate your commitment to supporting local suppliers by reporting on locally manufactured and supplied content, including goods produced and services provided by local industry.
              </p>
            </div> 
            <div className="about-card">
            <AccountTreeIcon/>
             <h3>Promote Local Added Value</h3>
              <p>
              Showcase the value of local content by reporting on construction activities carried out by local industry, emphasising the importance of local assembly and labour to project success.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};



import { useEffect } from "react";
import {Footer} from './Footer';
import hero from "assets/images/home/Company Reporting.png";
import { Header } from "./Header";
import arrowbtn from "assets/images/home/arrowBtn.png";
import InsightsIcon from '@mui/icons-material/Insights';
import AddchartIcon from '@mui/icons-material/Addchart';
import PeopleIcon from '@mui/icons-material/People';
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Implementation = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Company Performance</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Measure organisation-wide success
            </h3>
            <p>
            Monitor and assess your company's performance across multiple projects, to measure and report impact created at the organisational level.
            </p>
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
           
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
         <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <InsightsIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
              <h3>Enhanced Data Insights</h3>
              <p>
              Gain access to comprehensive data designed specifically for company reporting, providing valuable insights into performance metrics across an entire portfolio of projects.</p>
            </div>
            <div className="about-card">
            <AddchartIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
             <h3>Holistic Performance Analysis</h3>
              <p>
              Monitor and assess key priorities across various projects, enabling informed reporting and decision-making at the organisational level.
              </p>
            </div> 
            <div className="about-card">
            <PeopleIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
             <h3>Strategic Resource Allocation</h3>
              <p>
              Identify projects that require additional support or are falling behind others, facilitating strategic resource allocation to optimise overall company performance and project outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

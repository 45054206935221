//Public routes
export const home = "/";
export const pricing = "/pricing";
export const login = "/login";
export const forgotPassword = "/forgot-password";
export const resetPassword = "/reset-password";
export const verify = "/verify";
export const demo = "/request-demo";
export const reportingAndAnalytics = "/analytics-and-insights";
export const smartDirectory = "/smart-directory";
export const apiIntegration = "/api-integration";
export const implementation = "/company-performance";
export const blog = "/blog";
export const ourImpact = "/capabilities";
export const abouts = "/about-us";
export const definitions = "/definitions";
export const whoWeare = "/local-content";
export const support = "/support";
export const privacyPolicy = "/privacy-policy";
export const projectForecasting = "/project-forecasting";
export const termsConditions = "/terms-conditions";
export const measure = "/social-procurement";
export const manage = "/sustainability";
export const report = "/stakeholder-reporting";
export const industries = "/pricing";
export const blogDetail = (blogTitle?: string) => absolute(["blog", blogTitle]);

export const absolute = (params: (string | number | undefined)[]): string =>
  `/${params.filter((a) => Boolean(a)).join("/")}`;

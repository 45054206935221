import { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import PaymentsIcon from "@mui/icons-material/Payments";
import Procurement from "assets/images/home/Procurement.png";
import arrowbtn from "assets/images/home/arrowBtn.png";
import BadgeIcon from "@mui/icons-material/Badge";
export const Measure = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns ">
            <h4>Social Procurement Reporting</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
              Streamlined data collection
            </h3>
            <p>
              Track subcontractor reports, maintain an audit trail with
              supporting documents, and streamline data collection to meet
              reporting obligations in the most efficient way possible.
            </p>
            <a
              href="/request-demo"
              className="discover"
              style={{
                alignItems: "center",
                display: "flex",
                gap: "10px",
              }}
            >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
            </a>
          </div>
          <div className="zig-hero-img">
            <img src={Procurement} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
        <div className="container-new">
          <div className="servicecards cardswrapper two-col-wrapper">
            <div className="about-card">
              <BadgeIcon />
              <h3>Report employee hours</h3>
              <p>
                Effortlessly manage employee data by entering and storing all
                necessary information upfront, eliminating the need for
                repetitive data entry each month.
              </p>
            </div>
            <div className="about-card">
              <PaymentsIcon />
              <h3>Manage spend</h3>
              <p>
                Capture spend made out to local and social benefit suppliers to
                match against spend-based contractual obligations, and
                automatically validate eligibility of spend.
              </p>
            </div>
          </div>

          <div className="cta">
            <h3>Supporting global frameworks</h3>
            <p>
              Effortlessly meet audit requirements, report across national and
              global frameworks. Tailor to niche targets with custom standards
              or mix-and-match frameworks for your organisation’s needs.
            </p>
          </div>

          <div className="servicecards cardswrapper four-col-wrapper">
            <div className="about-card">
              <h3>Local Jobs First Policy (LJFP)</h3>
              <ul>
                <li>Local Jobs First (ANZ)</li>
                <li> Local Content (ANZ)</li>
                <li>Major Project Skills Guarantee (MPSG)</li>
              </ul>
            </div>
            <div className="about-card">
              <h3>Social Procurement Framework (SPF)</h3>
              <ul>
                <li> Victorian Priority Jobseekers</li>
                <li> Social / Disability Enterprise Spend</li>
              </ul>
            </div>
            <div className="about-card">
              <h3>Building Equality Plan (BEP)</h3>
              <ul>
                <li> Trade covered labour</li>
                <li> Non-trade Construction Award covered labour</li>
                <li>Management / supervisory and specialist labour</li>
                <li> Engaging Female Apprentices, Trainees & Cadets</li>
              </ul>
            </div>
            <div className="about-card">
              <h3>Indigenous Participation Plan (IPP)</h3>
              <ul>
                <li>Indigenous Participation </li>
                <li>Indigenous Business Spend</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

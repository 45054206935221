import { useEffect } from "react";
import { ContactForm } from "@stories/molecules/ContactForm/ContactForm";
import {Footer} from './Footer';
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const RequestDemo = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />

      {/* Contact Section  */}
      <div className="forms-section book-demo">
        <div className="container-new">
           <div className="demo-content">
           <h2>Book a demo</h2>
           <p>Explore how SocialPro can help deliver meaningful impact to your project.</p>
           </div>
            <div className="contact-form-new contact-form">
              <ContactForm />
            </div>
        </div>
      </div>

      {/* Footer Section  */}
     <Footer />
    </>
  );
};

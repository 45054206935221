import * as React from "react";

interface LayoutProps {
  children: React.ReactNode;
}

export const BasicLayout = (props: LayoutProps) => {
  const { children } = props;
  return <>{children}</>;
};

import { useEffect } from "react";
import {Footer} from './Footer';
import hero from "assets/images/home/Stakeholder Reporting.png";
import { Header } from "./Header";
import arrowbtn from "assets/images/home/arrowBtn.png";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Report = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Stakeholder Reporting</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Custom reports for stakeholders
            </h3>
            <p>
            Generate comprehensive and bespoke reports for clients and government stakeholders, showcasing your project's performance and compliance with reporting obligations.            </p>
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
    {/* cards Section  */}
    <div className="cards-wrapper">
      <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <ImageSearchIcon/>
              <h3>Transparent accountability</h3>
              <p>
              Demonstrate project performance and compliance with reporting obligations to both public and private clients, fostering trust and transparency in project management.</p>
            </div>
            <div className="about-card">
            <AssessmentIcon/>
             <h3>Tailored reporting requirements</h3>
              <p>
              Custom exports cater to complex reporting requirements, ensuring stakeholders receive relevant and comprehensive insights tailored to their specific needs and expectations.
              </p>
            </div> 
            <div className="about-card">
            <SpeakerNotesIcon/>
             <h3>Streamlined communication</h3>
              <p>
              PDF exports simplify monthly stakeholder reporting, enabling efficient communication of project progress and compliance status to government and private buyers.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
        <Footer />
    </>
  );
};

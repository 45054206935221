import { useEffect } from "react";
import Slider from "react-slick";
import hero from "assets/images/home/Smart Directory.png";
import arrowbtn from "assets/images/home/arrowBtn.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import PowerInputOutlinedIcon from "@mui/icons-material/PowerInputOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
const settingsAboutSlider3 = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        dots: true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ],
  slidesToShow: 9,
};
export const SmartDirectory = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />
      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Smart Directory</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
              Linking decision makers to suppliers
            </h3>
            <p>
              Connect with suppliers that can support your contractual
              obligations through our Smart Directory, promoting impactful
              partnerships and fulfilling social and sustainability targets.{" "}
            </p>
            <a
              href="/request-demo"
              className="discover"
              style={{
                alignItems: "center",
                display: "flex",
                gap: "10px",
              }}
            >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
            </a>
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper report-slider ">
        <div className="container-new about-new-section testimonial  report-testi ">
          <div
            className="about-cards-section servicecards"
            style={{ padding: 0, position: "relative" }}
          >
            <Slider {...settingsAboutSlider3}>
              <div className="about-card">
                  <LocationOnOutlinedIcon />
                  <h3>Local Suppliers</h3>
              </div>
                <div className="about-card">
                  <WebhookOutlinedIcon />
                  <h3>Social Enterprises</h3>
              </div>
              <div className="about-card">
              <PowerInputOutlinedIcon />
              <h3>Indigenous Suppliers</h3>
              </div>
              <div className="about-card">
              <PeopleAltOutlinedIcon />
              <h3>Indigenous Participation</h3>
              </div>
              <div className="about-card">
              <PersonPinCircleOutlinedIcon />
              <h3>Local Jobs</h3>
              </div>
              <div className="about-card">
              <Groups2OutlinedIcon />
              <h3>Female Participation</h3>
              </div>
              <div className="about-card">
              <GroupAddOutlinedIcon />
              <h3>Apprentices, Trainees & Cadets</h3>
              </div>
              <div className="about-card">
              <Diversity3OutlinedIcon />
              <h3>Victorian Priority Workforce</h3>
              </div>
              <div className="about-card">
              <SwitchAccountOutlinedIcon />
              <h3>
                Custom supplier types, linked and tailored to project
                commitments
              </h3>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />

    </>
  );
};

import { useContext } from "react";
import { ConfigContext } from "@hooks/context/ConfigContext";

// Custom hook to access the ConfigContext
const useConfig = () => {
  const config = useContext(ConfigContext);
  return config;
};

export default useConfig;

import { createContext, ReactNode, useCallback } from "react";
import defaultConfig from "config";
import useLocalStorage from "@hooks/utils/useLocalStorage";
import { CustomizationProps } from "types/config";

const initialState: CustomizationProps = {
  ...defaultConfig,
  onChangeBorderRadius: () => {
    alert("Not implemented");
  },
  onChangeContainer: () => {
    alert("Not implemented");
  },
  onChangeDrawer: () => {
    alert("Not implemented");
  },
  onChangeFontFamily: () => {
    alert("Not implemented");
  },
  onChangeLayout: () => {
    alert("Not implemented");
  },
  onChangeLocale: () => {
    alert("Not implemented");
  },
  onChangeMenuType: () => {
    alert("Not implemented");
  },
  onChangeOutlinedField: () => {
    alert("Not implemented");
  },
  onChangePresetColor: () => {
    alert("Not implemented");
  },
  onChangeRTL: () => {
    alert("Not implemented");
  },
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage("berry-config-ts", {
    borderRadius: initialState.borderRadius,
    drawerType: initialState.drawerType,
    fontFamily: initialState.fontFamily,
    layout: initialState.layout,
    locale: initialState.locale,
    navType: initialState.navType,
    outlinedFilled: initialState.outlinedFilled,
    presetColor: initialState.presetColor,
    rtlLayout: initialState.rtlLayout,
  });

  const updateConfig = useCallback(
    (newConfig: any) => {
      setConfig({
        ...config,
        ...newConfig,
      });
    },
    [config, setConfig],
  );

  const onChangeLayout = useCallback(
    (layout: any) => {
      updateConfig({ layout });
    },
    [updateConfig],
  );

  const onChangeDrawer = useCallback(
    (drawerType: any) => {
      updateConfig({ drawerType });
    },
    [updateConfig],
  );

  const onChangeMenuType = useCallback(
    (navType: any) => {
      updateConfig({ navType });
    },
    [updateConfig],
  );

  const onChangePresetColor = useCallback(
    (presetColor: any) => {
      updateConfig({ presetColor });
    },
    [updateConfig],
  );

  const onChangeLocale = useCallback(
    (locale: any) => {
      updateConfig({ locale });
    },
    [updateConfig],
  );

  const onChangeRTL = useCallback(
    (rtlLayout: any) => {
      updateConfig({ rtlLayout });
    },
    [updateConfig],
  );

  const onChangeContainer = useCallback(() => {
    updateConfig({ container: !config.container });
  }, [config, updateConfig]);

  const onChangeFontFamily = useCallback(
    (fontFamily: any) => {
      updateConfig({ fontFamily });
    },
    [updateConfig],
  );

  const onChangeBorderRadius = useCallback(
    (event: any, newValue: any) => {
      updateConfig({ borderRadius: newValue as number });
    },
    [updateConfig],
  );

  const onChangeOutlinedField = useCallback(
    (outlinedFilled: any) => {
      updateConfig({ outlinedFilled });
    },
    [updateConfig],
  );

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeBorderRadius,
        onChangeContainer,
        onChangeDrawer,
        onChangeFontFamily,
        onChangeLayout,
        onChangeLocale,
        onChangeMenuType,
        onChangeOutlinedField,
        onChangePresetColor,
        onChangeRTL,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };

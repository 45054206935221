import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastOptions, toast } from "react-toastify";
import { Typography } from "@mui/material";
import { API_END_POINT_AUTH } from "init";

// ===========================|| CONTACT CARD - FORMS ||=========================== //

export const sendContactEmail = async (
  firstName: string,
  lastName: string,
  company: string,
  phone: string,
  email: string,
) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({
      company,
      email,
      firstName,
      lastName,
      method: "demo-request",
      phone,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

export const verifyRecaptcha = async (token: string) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({ token }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

export const ContactForm = () => {
  const captchaRef = useRef<ReCAPTCHA>(null);

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    // subject: Yup.string().required("Subject is required"),
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Phone Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      company: "",
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
    },
    onSubmit: async (values) => {
      if (captchaRef.current) {
        try {
          const token = captchaRef.current.getValue();
          if (token) {
            const res = await verifyRecaptcha(token);
            if (res.status) {
              sendContactEmail(
                values.firstName,
                values.lastName,
                values.company,
                values.phone,
                values.email,
              );
              toast("Message sent - we will respond shortly!", {
                type: "success",
              } as ToastOptions);
              formik.resetForm();
            } else {
              toast("Recaptcha failed! Nice try, robot.", {
                type: "error",
              } as ToastOptions);
            }
            captchaRef.current.reset();
          } else {
            toast("Recaptcha not valid", {
              type: "error",
            } as ToastOptions);
          }
        } catch (e) {
          toast("Failed to send - something went wrong!", {
            type: "error",
          } as ToastOptions);
        }
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="inputs-cont">
        <div className="input-wrap">
          <label>First Name *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            placeholder="First Name"
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <Typography
              className="danger"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {formik.errors.firstName ? formik.errors.firstName : null}
            </Typography>
          ) : null}
        </div>
        <div className="input-wrap">
          <label>Last Name *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            placeholder="Last Name"
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <Typography
              className="danger"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {formik.errors.lastName ? formik.errors.lastName : null}
            </Typography>
          ) : null}
        </div>
        <div className="input-wrap">
          <label>Email Address *</label>
          <input
            type="email"
            id="email"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="e.g., email@example.com"
          />
          {formik.touched.email && formik.errors.email ? (
            <Typography
              className="danger"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {formik.errors.email ? formik.errors.email : null}
            </Typography>
          ) : null}
        </div>
        <div className="input-wrap">
          <label>Phone Number *</label>
          <input
            type="text"
            id="phone"
            name="phone"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            placeholder="e.g., +61 123 456 789"
          />
          {formik.touched.phone && formik.errors.phone ? (
            <Typography
              className="danger"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {formik.errors.phone ? formik.errors.phone : null}
            </Typography>
          ) : null}
        </div>

        <div className="input-wrap">
          <label>Company Name *</label>
          <input
            type="text"
            id="company"
            name="company"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
            placeholder="Company Name"
          />
          {formik.touched.company && formik.errors.company ? (
            <Typography
              className="danger"
              color={(theme) => {
                return theme.palette.error.main;
              }}
            >
              {formik.errors.company ? formik.errors.company : null}
            </Typography>
          ) : null}
        </div>

        <div className="input-wrap recaptcha">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey="6Le2DL0mAAAAAHGi46Uf3NpJS7yRih2-gNExcpS4"
            aria-disabled={formik.isSubmitting}
          />
          <button
            type="submit"
            className="contact-form-btn website-form-btn"
            disabled={formik.isSubmitting}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

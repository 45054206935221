import { useEffect } from "react";
import {Footer} from './Footer';
import hero from "assets/images/home/Sustainability.png";
import { Header } from "./Header";
import arrowbtn from "assets/images/home/arrowBtn.png";
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import GridOnIcon from '@mui/icons-material/GridOn';
import FoundationIcon from '@mui/icons-material/Foundation';
import ForestIcon from '@mui/icons-material/Forest';
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Manage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Sustainability Reporting</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Track and report sustainability metrics
            </h3>
            <p>
            Track and report on sustainability metrics like water, waste, fuels, and materials usage, ensuring your projects meet environmental goals.</p>
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
           
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
      <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <WaterDropIcon/>
              <h3>Water</h3>
              <p>
              Track potable and non-potable water usage, on-site reuse, and water discharge efficiently and accurately.</p>
            </div>
            <div className="about-card">
            <LocalGasStationIcon/>
             <h3>Fuels</h3>
              <p>
              Measure and report on diesel, petrol, biodiesel, oils, and other liquid fuels used in plant, equipment, and heavy vehicles.
              </p>
            </div> 
            <div className="about-card">
            <DeleteSweepIcon/>
             <h3>Waste</h3>
              <p>
              Monitor and document building material waste, detailing landfill disposal and reuse/recycling efforts.
              </p>
            </div>
            <div className="about-card">
            <GridOnIcon/>
             <h3>Concrete</h3>
              <p>
              Measure all concrete supplied to the construction site, including precast and in-situ components.
              </p>
            </div>
            <div className="about-card">
            <FoundationIcon/>
             <h3>Steel</h3>
              <p>
              Record and report on all structural and reinforcing steel supplied to the construction site.
              </p>
            </div>
            <div className="about-card">
            <ForestIcon/>
             <h3>Timber</h3>
              <p>
              Track timber product types and quantities used on-site for sustainability reporting and analysis.
              </p>
            </div>     
          </div>

        </div>
      </div>

      {/* Footer Section  */}
      <Footer/>
    </>
  );
};

import { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import {Footer} from './Footer';
import socialLinks from "./BlogData";
import { Header } from "./Header";
import "./style.css";

export const Blog = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  /* blog data */
  // const socialLinks = [
  // {
  //   title: "7 Benefits of Construction Procurement Software",
  //   img: blog1,
  //   link: "https://procurepro.co/blog/7-benefits-of-construction-procurement-software/",
  //   text: "Dedicated construction procurement software gets rid of your reliance on the time-consuming and admin-related tasks associated with procurement.",
  // },
  // {
  //   title: "7 Steps Of The Procurement Process",
  //   img: blog2,
  //   link: "https://procurepro.co/blog/7-steps-of-the-procurement-process/",
  //   text: "Procurement involves many moving parts, and when you put it in the context of large-scale construction projects - such as residential apartment buildings, schools or hospitals - the process often spans multiple years and involves the procurement of 30-100 subcontractors, thousands of separate materials and items.",
  // },
  // {
  //   title: "The Treasure Trove of Construction: Procurement Data",
  //   img: blog3,
  //   link: "https://procurepro.co/blog/the-treasure-trove-of-construction-procurement-data/",
  //   text: "“Procurement is the biggest risk for our business” is a statement that has echoed through the construction industry over the last three years. Procurement has always been mission-critical to setting projects up for success. ",
  // },
  // ];

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>News & Articles</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            All things social procurement
          </h2>
          <p>
            Learn about the latest in social procurement, construction, new
            product features, solutions, and updates.
          </p>
        </div>
      </div>

      {/* Blog section */}
      <div className="blog-section">
        <div className="container-new">
          <div
            className="blogs-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {socialLinks.map((blog: any, index) => {
              const urlTitle = blog.title.replace(/ /g, "-");

              return (
                <div className="blog" key={index}>
                  <div className="blog-img">
                    <Link to={`/blog/${urlTitle}`}>{<blog.img />}</Link>
                  </div>
                  <h3>
                    <Link to={`/blog/${urlTitle}`}>{blog.title}</Link>
                  </h3>
                  <p>{`${blog.text.substr(0, 150).replace("<p>", "")}...`}</p>
                  <h4>Published on {blog.publish}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Logo from "assets/images/home/footer-logo-new.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logoBlue from "assets/images/home/SocialPro_Blue.png";
import hamburger from "assets/images/home/hamburger.png";
import filebox from "assets/images/home/file-box.png";
import sprout from "assets/images/home/sprout.png";
import users from "assets/images/home/users.png";
import globe from "assets/images/home/globe.png";
import landplot from "assets/images/home/land-plot.png";
import calendarclock from "assets/images/home/calendar-clock.png";
import search from "assets/images/home/search.png";
import boxes from "assets/images/home/boxes.png";
import blueuser from "assets/images/home/blue-users.png";
import greensearch from "assets/images/home/green-search.png";
import pluscircle from "assets/images/home/plus-circle.png";
import scrolltext from "assets/images/home/scroll-text.png";
import skyblueusers from "assets/images/home/skyblue-users.png";
import boxespurple from "assets/images/home/boxes-purple.png";
import scrolltextblue from "assets/images/home/scroll-text-blue.png";
import alertcircle from "assets/images/home/alert-circle.png";

export const HeaderHome = () => {
  const [IsActive, setIsActive] = useState(false);
  const [menuItems, setMenuItems] = useState([
    {
      isOpen: false,
      items: [
        {
          description:
            "Manage and submit employee hours and spend in one place.",
          img: filebox,
          label: "Social Procurement",
          url: "/social-procurement",
        },
        {
          description: "Track key metrics including fuel, water and waste.",
          img: sprout,
          label: "Sustainability",
          url: "/sustainability",
        },
        {
          description: "Download project-level reports in PDF and CSV formats.",
          img: users,
          label: "Stakeholder Reporting",
          url: "/stakeholder-reporting",
        },
        {
          description:
            "Gain oversight of impact created at the organisational level.",
          img: globe,
          label: "Company Performance",
          url: "/company-performance",
        },
        {
          description:
            "Monitor performance against agreed local procurement targets.",
          img: landplot,
          label: "Local Content",
          url: "/local-content",
        },
        {
          description:
            "Match target projections against construction works program.",
          img: calendarclock,
          label: "Project Forecasting",
          url: "/project-forecasting",
        },
        {
          description:
            "Analytics on performance against targets for informed decisions.",
          img: search,
          label: "Analytics & Insights",
          url: "/analytics-and-insights",
        },
        {
          description:
            "Search from a list of certified and registered suppliers.",
          img: boxes,
          label: "Smart Directory",
          url: "/smart-directory",
        },
      ],
      label: "Features",
    },
    {
      isOpen: false,
      items: [
        {
          description:
            "We partner with a range of industry experts and software providers.",
          img: blueuser,
          label: "Partners & Integrations",
          url: "/api-integration",
        },
        {
          description:
            "View our online guides and support videos to get you started.",
          img: pluscircle,
          label: "Support Services",
          url: "/support",
        },
        {
          description: "To stay up to date with definitions and terminology.",
          img: greensearch,
          label: "Industry Definitions",
          url: "/definitions",
        },
        {
          description:
            "Read up articles on the latest industry and company news.",
          img: scrolltext,
          label: "Blog",
          url: "/blog",
        },
      ],
      label: "Resources",
    },
    {
      isOpen: false,
      items: [
        {
          description:
            " Empower contractors with cloud tech for ESG management.",
          img: boxespurple,
          label: "Capabilities",
          url: "/capabilities",
        },
        {
          description:
            "Learn more about the SocialPro team and company vision.",
          img: skyblueusers,
          label: "About Us",
          url: "/about-us",
        },
        {
          description:
            "Access our privacy policy for information on data security and privacy.",
          img: alertcircle,
          label: "Privacy Policy",
          url: "/privacy-policy",
        },
        {
          description:
            " Review our terms and conditions for detailed guidelines.",
          img: scrolltextblue,
          label: "Terms & Conditions ",
          url: "/terms-conditions",
        },
      ],
      label: "Company",
    },
    {
      isOpen: false,
      items: [{ description: "", img: "", label: "Pricing", url: "/pricing" }],
      label: "Pricing",
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenuItems((prevMenuItems) => {
      const updatedMenuItems = [...prevMenuItems];
      updatedMenuItems[menuIndex].isOpen = !updatedMenuItems[menuIndex].isOpen;
      return updatedMenuItems;
    });
  };

  return (
    <header className="header-default">
      <div className="container-new">
        <div className="navigation">
          <Link to="/" className="site-logo">
            <img src={Logo} alt="socialpro" />
          </Link>

          <ul>
            <li>
              <span>Features</span>
              <ul className="dropdown-content megamenu">
                <h3>Product Features</h3>
                <ul>
                  <li>
                    <Link to="/social-procurement">
                      <div>
                        <img src={filebox} alt="" />
                      </div>
                      <div>
                        <h5>Social Procurement</h5>
                        <p>
                          Manage and submit employee hours and spend in one
                          place.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/sustainability">
                      <div>
                        <img src={sprout} alt="" />
                      </div>
                      <div>
                        <h5>Sustainability</h5>
                        <p>
                          Track key metrics including fuel, water and waste.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/stakeholder-reporting">
                      <div>
                        <img src={users} alt="" />
                      </div>
                      <div>
                        <h5>Stakeholder Reporting</h5>
                        <p>
                          Download project-level reports in PDF and CSV formats.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/company-performance">
                      <div>
                        <img src={globe} alt="" />
                      </div>
                      <div>
                        <h5>Company Performance</h5>
                        <p>
                          Gain oversight of impact created at the organisational
                          level.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/local-content">
                      <div>
                        <img src={landplot} alt="" />
                      </div>
                      <div>
                        <h5>Local Content</h5>
                        <p>
                          Monitor performance against agreed local procurement
                          targets.
                        </p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/project-forecasting">
                      <div>
                        <img src={search} alt="" />
                      </div>
                      <div>
                        <h5>Project Forecasting</h5>
                        <p>
                          Match target projections against construction works
                          program.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/analytics-and-insights">
                      <div>
                        <img src={calendarclock} alt="" />
                      </div>
                      <div>
                        {" "}
                        <h5>Analytics & Insights</h5>
                        <p>
                          Analytics on performance against targets for informed
                          decisions.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/smart-directory">
                      <div>
                        <img src={boxes} alt="" />
                      </div>
                      <div>
                        {" "}
                        <h5>Smart Directory</h5>
                        <p>
                          Search from a list of certified and registered
                          suppliers.
                        </p>
                      </div>
                    </Link>
                  </li>
                </ul>
                <a href="/request-demo" className="btn grey-btn">
                  Book a demo{" "}
                  <svg
                    style={{ width: "10px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      fill="black"
                    />
                  </svg>
                </a>
              </ul>
            </li>
            <li>
              <span>Resources</span>
              <ul className="dropdown-content megamenu">
                <ul>
                  <li>
                    <Link to="/api-integration">
                      <div>
                        <img src={blueuser} alt="" />
                      </div>
                      <div>
                        <h5>Partners & Integrations</h5>
                        <p>
                          We partner with a range of industry experts and
                          software providers.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/support">
                      <div>
                        <img src={pluscircle} alt="" />
                      </div>
                      <div>
                        <h5>Support Services</h5>
                        <p>
                          View our online guides and support videos to get you
                          started.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/definitions">
                      <div>
                        <img src={greensearch} alt="greensearch" />
                      </div>
                      <div>
                        <h5>Industry Definitions</h5>
                        <p>
                          To stay up to date with definitions and terminology.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <div>
                        <img src={scrolltext} alt="scrolltext" />
                      </div>
                      <div>
                        <h5>Blog</h5>
                        <p>
                          Read up articles on the latest industry and company
                          news.
                        </p>
                      </div>
                    </Link>
                  </li>
                </ul>
                <a href="/request-demo" className="btn grey-btn">
                  Book a demo{" "}
                  <svg
                    style={{ width: "10px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      fill="black"
                    />
                  </svg>
                </a>
              </ul>
            </li>
            <li>
              <span>Company</span>
              <ul className="dropdown-content megamenu">
                <ul>
                  <li>
                    <Link to="/capabilities">
                      <div>
                        <img src={boxespurple} alt="" />
                      </div>
                      <div>
                        <h5>Capabilities</h5>
                        <p>
                          Empower contractors with cloud tech for ESG
                          management.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <div>
                        <img src={skyblueusers} alt="" />
                      </div>
                      <div>
                        <h5>About Us</h5>
                        <p>
                          Learn more about the SocialPro team and company
                          vision.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <div>
                        <img src={alertcircle} alt="greensearch" />
                      </div>
                      <div>
                        <h5> Privacy Policy</h5>
                        <p>
                          Access our privacy policy for information on data
                          security and privacy.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">
                      <div>
                        <img src={scrolltextblue} alt="scrolltext" />
                      </div>
                      <div>
                        <h5>Terms & Conditions</h5>
                        <p>
                          Review our terms and conditions for detailed
                          guidelines.
                        </p>
                      </div>
                    </Link>
                  </li>
                </ul>
                <a href="/request-demo" className="btn grey-btn">
                  Book a demo{" "}
                  <svg
                    style={{ width: "10px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      fill="black"
                    />
                  </svg>
                </a>
              </ul>
            </li>
            <li className="pricing">
              <Link to="/pricing"> Pricing </Link>
            </li>
          </ul>
          <div className="header-btn">
            <Button
              size="large"
              variant="contained"
              component={Link}
              to="/request-demo"
            >
              Demo
            </Button>

            <Button
              size="large"
              variant="outlined"
              component={Link}
              to="https://portal.socialproapp.com/login"
            >
              Sign In
            </Button>
          </div>
          <button
            type="button"
            className="hamburger-menu"
            onClick={() => setIsActive(!IsActive)}
          >
            <img
              src={hamburger}
              alt=""
              style={{ height: "30px", width: "30px" }}
            />
          </button>
        </div>
      </div>
      {/* Mob Navigation  */}

      <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "30px 20px 0",
          }}
        >
          <Link to="/" className="site-logo">
            <img src={logoBlue} alt="socialpro" />
          </Link>
          <button
            type="button"
            className="hamburger-menu"
            onClick={() => setIsActive(!IsActive)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#05214b"
              height={32}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="mob-nav">
          <div className="mob-nav-inner">
            <ul>
              {menuItems.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex} style={{ fontFamily: "Roboto" }}>
                          <div>
                            <Link to={item.url}>{item.label}</Link>
                            <p>{item.description}</p>
                          </div>
                          <div>
                            <img src={item.img} alt="" />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo">Demo</a>
                <a
                  href="https://portal.socialproapp.com/login"
                  className="nav-login"
                >
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="https://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#0099fa"
                  >
                    <g>
                      <path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0" />
                    </g>
                  </svg>
                  <span>Sign In</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

import { useRef } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "assets/images/home/footer-logo-new.svg";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import spreadsheet from "assets/images/home/spreadsheet.png";
import ReCAPTCHA from "react-google-recaptcha";
import { API_END_POINT_AUTH } from "init";
import arrowbtn from "assets/images/home/arrowBtn.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@mui/material";
import { ToastOptions, toast } from "react-toastify";

export const sendNewsLetterEmail = async (email: string) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({ email, method: "newsletter" }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

export const verifyRecaptcha = async (token: string) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({ token }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};
export const Footer = () => {
  const captchaRef = useRef<ReCAPTCHA>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      if (captchaRef.current) {
        try {
          const token = captchaRef.current.getValue();
          if (token) {
            const res = await verifyRecaptcha(token);
            if (res.status) {
              sendNewsLetterEmail(values.email);
              toast("Newsletter request sent!", {
                type: "success",
              } as ToastOptions);
              formik.resetForm();
            } else {
              toast("Recaptcha failed! Nice try, robot.", {
                type: "error",
              } as ToastOptions);
            }
            captchaRef.current.reset();
          } else {
            toast("Recaptcha not valid", {
              type: "error",
            } as ToastOptions);
          }
        } catch (e) {
          toast("Failed to send - something went wrong!", {
            type: "error",
          } as ToastOptions);
        }
      }
    },
    validationSchema: validationSchema,
  });
  return (
    <>
      <div className="spreadsheet">
        <div className="container-new">
          <div className="zigzag">
            <div className="zig-content">
              <div className="zig-inner-content">
                <h3>Ready to ditch the spreadsheets?</h3>
                <p>
                  Track subcontractor reports, maintain an audit trail with
                  supporting documents, and streamline data collection to meet
                  reporting obligations in the most efficient way possible.
                </p>
                <div className="btns-wrap hero-btns">
                  {/* <a href="/measure" className="btn btn-grey">
                  Discover more
                </a> */}
                  <a
                    href="/request-demo"
                    className="discover"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    Request a demo
                    <img src={arrowbtn} alt="arrowbtn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="zig-img">
              <img src={spreadsheet} alt="" />
            </div>
          </div>
          <div className="signup">
            <div className="signup-col-left">
              <AutoAwesomeMotionOutlinedIcon
                style={{ color: "white", fontSize: "40px" }}
              />
              <h3>Know what you’ll pay</h3>
              <p>
                Let us provide you with a custom quote by answering a few simple
                questions.
              </p>
              <a href="/pricing">
                Get a quote{" "}
                <svg
                  style={{ width: "10px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
            <div className="signup-col-right">
              <div>
                <MarkEmailReadOutlinedIcon
                  style={{ color: "white", fontSize: "40px" }}
                />
                <h3>Sign-up to our newsletter</h3>
                <p>Get the latest insights in construction and technology.</p>
                <span>
                  Enter your email
                  <svg
                    style={{ width: "10px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="inputs-cont">
                    <div className="input-wrap">
                      <label htmlFor="email" style={{ color: "white" }}>
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <Typography
                          className="danger"
                          sx={{ color: "#ff5630 !important" }}
                          color={(theme) => {
                            return theme.palette.error.main;
                          }}
                        >
                          {formik.errors.email ? formik.errors.email : null}
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-wrap recaptcha">
                    <div
                      className="g-recaptcha"
                      data-sitekey="6Le2DL0mAAAAAHGi46Uf3NpJS7yRih2-gNExcpS4"
                      data-size="compact"
                    >
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey="6Le2DL0mAAAAAHGi46Uf3NpJS7yRih2-gNExcpS4"
                      />
                    </div>
                    <button className="website-form-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerbg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social">
              <h5>100% Australian owned and developed.</h5>
              <p>
                SocialPro acknowledges the Australian Aboriginal and Torres
                Strait Islander peoples as the first inhabitants of the nation
                and the traditional custodians of the lands where we live, learn
                and work.
              </p>
              <p>
                <span>© SocialPro 2024. All Rights Reserved.</span>
              </p>
            </div>
          </div>
          <div className="footer-links-rightcol">
            <div className="footer-links">
              <h4>Product</h4>
              <ul>
                <li>
                  <Link to="/social-procurement">Social Procurement</Link>
                </li>
                <li>
                  <Link to="/sustainability">Sustainability</Link>
                </li>
                <li>
                  <Link to="/stakeholder-reporting">Stakeholder Reporting</Link>
                </li>
                <li>
                  <Link to="/company-performance">Company Performance</Link>
                </li>
                <li>
                  <Link to="/local-content">Local Content</Link>
                </li>
                <li>
                  <Link to="/project-forecasting">Project Forecasting</Link>
                </li>
                <li>
                  <Link to="/analytics-and-insights">Analytics & Insights</Link>
                </li>
                <li>
                  <Link to="/smart-directory">Smart Directory </Link>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <h4>Resources</h4>
              <ul>
                <li>
                  <Link to="/request-demo">Book a demo</Link>
                </li>
                <li>
                  <Link to="/api-integration">Partners & Integrations</Link>
                </li>
                <li>
                  <Link to="/support">Support Services</Link>
                </li>
                <li>
                  <Link to="/definitions">Industry Definitions</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
              <h5>Commercials</h5>
              <ul>
                <li>
                  <Link to="/pricing"> Pricing </Link>
                </li>
              </ul>
            </div>
            <div className="footer-links footer-last-col">
              <div className="col">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to="/capabilities"> Capabilities </Link>
                  </li>
                  <li>
                    <Link to="/about-us"> About Us </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5>Socials</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/socialpro-app/"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/socialpro.au/"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.socialtraders.com.au/find-a-social-enterprise?staging_accounts%5Bquery%5D=socialpro&staging_accounts%5Bmenu%5D%5Blocation%5D=VIC&staging_accounts%5Bmenu%5D%5Bsupplier_product_service%5D=Community%20%26%20Social%20Services&staging_accounts%5Btoggle%5D%5Bprofile_published__c%5D=true&staging_accounts%5Btoggle%5D%5Bsocial_enterprise_finder__c%5D=true&staging_accounts%5BrefinementList%5D%5Bstatus__c%5D%5B0%5D=Certified&staging_accounts%5BrefinementList%5D%5Bstatus__c%5D%5B1%5D=Certified%20%28Grace%20Period%29"
                      target="_blank"
                    >
                      Social Traders
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-social mobile-show">
                <h5>100% Australian owned and developed.</h5>
                <p>
                  SocialPro acknowledges the Australian Aboriginal and Torres
                  Strait Islander peoples as the first inhabitants of the nation
                  and the traditional custodians of the lands where we live,
                  learn and work.
                </p>
                <p>
                  <span>© SocialPro 2024. All Rights Reserved.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

import Blog1 from "../../../assets/images/blogs/Blog_1.jpg";
import Blog2 from "../../../assets/images/blogs/Blog_2.jpg";
import Blog3 from "../../../assets/images/blogs/Blog_3.webp";
import Blog4 from "../../../assets/images/blogs/Blog_4.jpg";
import Blog5 from "../../../assets/images/blogs/Blog_5.jpg";
import Blog6 from "../../../assets/images/blogs/Blog_6.jpg";

const styles = {
  border: "2px solid black",
  display: "contents",
  maxWidth: "100%",
};
const imgStyle = {
  width: "-webkit-fill-available",
};

export function Blog1Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog1} />
    </div>
  );
}
export function Blog2Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog2} />
    </div>
  );
}
export function Blog3Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog3} />
    </div>
  );
}
export function Blog4Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog4} />
    </div>
  );
}
export function Blog5Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog5} />
    </div>
  );
}
export function Blog6Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog6} />
    </div>
  );
}

import { Buffer } from "buffer";
Buffer.from("anything", "base64");

import ErrorBoundary from "./views/ErrorBoundary/ErrorBoundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteInfo, getRoutes } from "routes/routes";
import ScrollToTop from "@stories/atoms/ScrollToTop/ScrollToTop";
import ThemeCustomization from "themes";
import { ToastContainer } from "react-toastify";
import Locales from "@stories/ui-component/Locales";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import "./App.css";

const RoutedPath = (index: number, routeInfo: RouteInfo) => {
  const Layout = routeInfo.layout;
  const Component = routeInfo.component;
  return (
    <Route
      key={`route-${index}`}
      index={routeInfo.index}
      path={routeInfo.path}
      element={<Layout children={<Component />} />}
    />
  );
};

const AuthenticatedRouter = () => {
  const routes = getRoutes();
  const defaultPath = routes.find((r) => r.index);

  if (!defaultPath) {
    throw Error("No default path defined for this set of routes");
  }
  return (
    <Routes>
      {routes.map((r, index) => RoutedPath(index, r))}
      <Route path="*" element={<Navigate to={defaultPath.path} replace />} />
    </Routes>
  );
};

function App() {
  return (
    <ErrorBoundary>
      <ThemeCustomization>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Locales>
            <ScrollToTop />
            <AuthenticatedRouter />
            <ToastContainer position="bottom-right" />
          </Locales>
        </LocalizationProvider>
      </ThemeCustomization>
    </ErrorBoundary>
  );
}

export default App;

import { useEffect } from "react";
import { Footer } from "./Footer";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Support = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const accordionData = [
    {
      content:
        "SocialPro is a web-based application that serves as a SaaS platform for reporting on social procurement performance, on major building, rail and infrastructure projects. It collects and consolidates social procurement information from all stackholders, and provides analytics and insights into performance at both the package and project level.",
      id: 1,
      title: "What is SocialPro?",
    },
    {
      content:
        "SocialPro provides a comprehensive solution for contractors and subcontractors to efficiently report on and monitor their Social Procurement participation & spend, across all commitments applicable to their project. It streamlines the data input process, automates consolidation and reporting with analytics and insights, and enhances transparency, to help compliance requirements while promoting social and economic benefits. Better information leads to better decision-making, and optimising social procurement performance.",
      id: 2,
      title: "How can SocialPro help contractors and subcontractors? ",
    },
    {
      content:
        "Social Procurement plays a vital role in the construction industry as it helps foster inclusive economic growth, supports local communities, and promotes sustainable business practices. It allows contractors and subcontractors to make a positive impact, and create social value above and beyond the value of goods and services procured, through their purchasing decisions.",
      id: 3,
      title:
        "Why is Social Procurement important in the construction industry?",
    },
    {
      content:
        "SocialPro employs advanced tracking mechanisms to monitor Social Procurement spend and employee participation, specific to the commitments applicable to each individual project. Data across packages to a project are automatically consolidated to provide project-level insights. It integrates with project management tools, collects relevant labour and spend data, and generates insightful reports that illustrate performance against commitments, whilst highlighting areas for improvement.",
      id: 4,
      title: "How does SocialPro track and report on Social Procurement spend?",
    },
    {
      content:
        "Yes, SocialPro is designed to seamlessly integrate with various project management tools commonly used in the construction industry. This integration ensures a smooth workflow and allows users to leverage their existing systems to streamline the data input process and minimise duplication in business processes.",
      id: 5,
      title: "Can SocialPro integrate with existing project management tools? ",
    },
    {
      content: `SocialPro is suitable for all projects for which the Social Procurement Framework, Building Equality Policy, Local Jobs First Policy, and Indigenous Participation Policy are applicable. The platform is bespoke to address the various commitments that are expected of sellers under these Frameworks.

      Additionally, the platform can be tailored to meet any other type of project in which discretionary social procurement targets have been applied, in order to promote social and economic benefits to priority groups identified as beneficiaries to the project. 
      `,
      id: 6,
      title: "What types of projects is SocialPro suitable for? ",
    },

    {
      content:
        "Yes, SocialPro is scalable and can accommodate projects of different sizes. Whether it's a small-scale residential project or a large-scale multi-year infrastructure development, SocialPro can effectively track and report on Social Procurement performance.",
      id: 7,
      title: "Is SocialPro compatible with different project sizes? ",
    },
    {
      content:
        "SocialPro collects relevant data related to Social Procurement spend, supplier information, project details, and social impact metrics. This data is used to generate reports, analytics, and insights that enable users to make informed decisions and demonstrate their social value.",
      id: 8,
      title: "What data does SocialPro collect and how is it used? ",
    },
    {
      content:
        "SocialPro improves transparency in Social Procurement reporting by providing a centralized platform where contractors and subcontractors can track, document, and report their Social Procurement activities. It ensures accurate and reliable reporting, making it easier to demonstrate compliance and promote transparency to stakeholders.",
      id: 9,
      title:
        "How can SocialPro improve transparency in Social Procurement reporting? ",
    },
    {
      content:
        "Yes, SocialPro is equipped with robust reporting and analytics capabilities. It can generate comprehensive reports that showcase Social Procurement spend, inclusive employment, community impact, and other key metrics. These reports can be shared with stakeholders to showcase the social value created through procurement practices.",
      id: 10,
      title: "Can SocialPro generate reports and analytics for stakeholders?",
    },
    {
      content:
        "Yes, data security is a top priority for SocialPro. We implement stringent security measures to protect user data from unauthorized access, loss, or misuse. We use industry-standard encryption protocols, secure data storage, and regular backups to ensure the highest level of data security.",
      id: 11,
      title: "Is my data secure with SocialPro?",
    },
    {
      content:
        "Onboarding and using SocialPro is designed to be user-friendly and intuitive. We provide user guides, tutorials, and customer support to help users navigate the platform easily. Our goal is to ensure a smooth onboarding experience and minimize the learning curve for new users.",
      id: 12,
      title: "How easy is it to onboard and use SocialPro?",
    },
    {
      content:
        "Yes, we offer comprehensive training and support resources for SocialPro users. We provide documentation, video tutorials, and a dedicated support team to assist users with any questions or issues they may encounter. We are committed to ensuring our users have the resources they need to effectively utilize SocialPro.",
      id: 13,
      title:
        "Are there any training or support resources available for SocialPro users?",
    },
    {
      content:
        "We offer flexible pricing options for SocialPro based on the needs of our clients. Our pricing structure takes into account factors such as project size, user requirements, and additional features. Please contact our sales team to discuss pricing details and find a plan that suits your specific needs.",
      id: 14,
      title: "What pricing options are available for SocialPro?",
    },

    {
      content:
        "Yes, we offer demos and trials of SocialPro to interested customers. This allows you to explore the platform's features, interface, and functionality before making a purchasing decision. Please reach out to our sales team to schedule a demo or discuss trial options.      ",
      id: 15,
      title: "Can I request a demo or trial of SocialPro before purchasing?",
    },

    {
      content:
        "Getting in touch with our support team is easy. You can reach out to us through our dedicated support channels, including email, phone, or our online support portal. Our team is available to assist you with any questions, technical issues, or support requests you may have.",
      id: 16,
      title: "How can I get in touch with the SocialPro support team?",
    },
    {
      content:
        "Yes, SocialPro offers customisation options to meet the specific needs of your organization. We understand that different projects and companies have unique requirements, and we strive to accommodate them. Our team can work with you to tailor SocialPro to align with your specific goals and workflows.",
      id: 17,
      title:
        "Does SocialPro offer customisation options to meet specific needs?",
    },
    {
      content:
        "Absolutely, SocialPro is designed to support multiple users and teams. It allows you to invite team members, assign roles and permissions, and collaborate efficiently. Whether you have a small team or a large organization, SocialPro can accommodate your user and team management needs.",
      id: 18,
      title: "Can SocialPro accommodate multiple users and teams?",
    },

    // Add more accordion data as needed
  ];

  return (
    <>
      {/* Header Section  */}
      <Header />

      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>Support</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            We're here to help
          </h2>
          <p>
            Need some assistance with navigating SocialPro? Our team of
            dedicated support specialists is also on-hand to help you with any
            specific queries, enter your details below and our team will get
            back to you as soon as possible.
          </p>
        </div>
      </div>

      {/* new form Section  */}
      <div className="accordion-section support-section">
        <div className="container-new">
          <h2>Frequently asked questions</h2>
          <div
            className="accordion-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {accordionData.map((item) => (
              <Accordion
                key={item.id}
                defaultExpanded={item.id === 1}
                className="accordion-item"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordion-title"
                >
                  {item.title}
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                  <p>{item.content}</p>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-overlay question-cta ">
        <div className="container-new">
          <h3>Still have questions?</h3>
          <p>Chat to our friendly team.</p>
          <a href="/request-demo" className="btn-dark-blue">
            Get in touch
          </a>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

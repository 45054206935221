import { useEffect } from "react";
import { Footer } from "./Footer";
import hero from "assets/images/home/Forecasting.png";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import arrowbtn from "assets/images/home/arrowBtn.png";
import GradingIcon from '@mui/icons-material/Grading';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
export const Partner = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />
      {/* Hero Section  */}
      <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Project Forecasting</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Plan, map and resource efficiently
            </h3>
            <p>
            Predict and plan for project outcomes with our forecasting tools, allowing you to map out hours, spend, and sustainability usage against your program for better project management.
            </p>
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>
      {/* cards Section  */}
      <div className="cards-wrapper">
      <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <SensorOccupiedIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
              <h3>Mitigate Organisation Risks</h3>
              <p>
              Predict and mitigate potential shortfalls early in the project that could lead to missed targets, to identify opportunities for smoother project execution and delivery.</p>
            </div>
            <div className="about-card">
            <GradingIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
             <h3>Proactive Planning of Projects</h3>
              <p>
              Harness the power of predictive analytics to forecast project outcomes, enabling strategic resource allocation and anticipation of potential challenges before they arise.
              </p>
            </div> 
            <div className="about-card">
            <PeopleAltIcon style={{color:'#000', fontSize:'50px', marginBottom:'20px'}}/>
             <h3>Efficient Resource Allocation</h3>
              <p>
              Leverage advanced forecasting tools to  map out labour hours, expenditure, and sustainability metrics, ensuring optimal resource allocation for streamlined project management efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section  */}
      <Footer />
    </>
  );
};

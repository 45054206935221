import * as BlogImages from "./Images";

export default [
  {
    author: "Taylor Jenkins",
    id: 1,
    img: BlogImages.Blog1Component,
    publish: "13 Oct 2023",
    text: `<p>Revolutionising the construction industry goes beyond just building structures; it involves creating positive social change and promoting sustainable practices. However, one area that often hampers progress is the cumbersome process of data collection. Contractors find themselves caught up in administrative tasks, constantly chasing subcontractors for information to fill custom spreadsheets. This inefficient workflow not only consumes valuable time but also diverts attention from the essential task of educating and promoting social enterprises in the industry. It&rsquo;s time to address this issue and empower contractors to focus on what truly matters - making a difference.</p>

    <p>By adopting innovative technology solutions, the construction industry can significantly streamline data collection processes and alleviate the burden on contractors. A centralised platform, like SocialPro, can serve as a game-changer by automating data submission and reporting. With a few clicks, subcontractors can effortlessly input their data, ensuring accurate and real-time updates.</p>
    
    <p>With the administrative burden lifted, contractors can redirect their efforts towards educating and promoting social enterprises within the industry. They can actively seek out local suppliers and manufacturers that align with their social impact goals. By building strong partnerships with these businesses, contractors can drive economic growth in their communities and support the development of socially responsible practices.</p>
    
    <p>By leveraging technology to automate data collection, contractors have the opportunity to become advocates for social enterprises. They can dedicate their time to fostering relationships, educating subcontractors about the benefits of working with verified social enterprises, and highlighting the positive impact generated by these partnerships. Such promotion will not only enhance the reputation of social enterprises but also encourage their growth and sustainability.</p>
    
    <p>The construction industry has immense potential to be a catalyst for social change. By streamlining data collection processes through innovative platforms, contractors can reclaim their roles as drivers of positive impact. Liberated from mundane administrative tasks, contractors can focus on promoting social enterprises, fostering community growth, and championing sustainable practices. It&rsquo;s time to embrace technological advancements and empower contractors to make a real difference in the construction industry. Together, we can transform the way projects are executed and create a more socially conscious future.</p>`,
    title:
      "Streamlining Data Collection in Construction: Empowering Contractors for Social Impact",
  },
  // {
  //   id: 2,
  //   title:
  //     "Leveraging SocialPro's Smart Directory: Streamlining Subcontractor Selection for Enhanced Social Impact in Construction",
  //   author: "Tony Barkey",
  //   publish: "26 Sept 2023",
  //   img: BlogImages.Blog2Component,

  //   text: `<p>In the dynamic landscape of the construction industry, staying ahead of contractual targets while promoting social enterprises can be a daunting task. Contractors often find themselves burdened with the manual workload of partnering with subcontractors, searching for the right matches to fill shortfalls. However, with SocialPro&rsquo;s innovative Smart Directory, this process becomes streamlined and efficient. By automatically linking subcontractors&rsquo; shortfalls with relevant social enterprises, contractors can focus on driving social impact and achieving project goals without compromising valuable time and resources.</p>

  //   <p>SocialPro&rsquo;s Smart Directory revolutionizes the subcontractor selection process by intelligently linking shortfalls with suitable social enterprises. By considering factors such as target type, location, availability, and past work experience, the Smart Directory ensures optimal matches. Contractors no longer need to spend hours manually searching for suitable subcontractors. With just a few clicks, the Smart Directory presents a curated list of social enterprises that align with the project&rsquo;s specific requirements, saving contractors valuable time and effort.</p>

  //   <p>Beyond alleviating the administrative burden for contractors, SocialPro&rsquo;s Smart Directory empowers subcontractors to proactively meet their contractual targets. By presenting a comprehensive view of shortfalls and matching them with suitable projects, subcontractors can identify opportunities that align with their capabilities and expertise. This proactive approach enables subcontractors to stay ahead of the game and position themselves as reliable partners in the construction ecosystem.</p>

  //   <p>The integration of SocialPro&rsquo;s Smart Directory creates a powerful ecosystem that fosters collaboration between contractors and social enterprises. By connecting contractors with verified social enterprises, the construction industry becomes a catalyst for social change. Contractors can actively support social enterprises by awarding them projects that align with their social impact goals, fostering economic growth, and promoting sustainable practices.</p>

  //   <p>SocialPro&rsquo;s Smart Directory is a game-changer in the construction industry, empowering both contractors and subcontractors to drive social impact while efficiently meeting contractual targets. By automating the process of linking subcontractors&rsquo; shortfalls with relevant social enterprises, the Smart Directory streamlines operations, reduces manual workload, and promotes collaboration. Contractors can focus on nurturing relationships with social enterprises, fostering community growth, and achieving their project goals. Together, we can transform the construction industry into a force for positive change, where social impact and project success go hand in hand.</p>`,
  // },
  {
    author: "Taylor Jenkins",
    id: 3,
    img: BlogImages.Blog3Component,
    publish: "09 Sept 2023",
    text: `<p>In the competitive landscape of the construction industry, staying ahead of reporting and meeting contractual targets is crucial for head contractors and subcontractors. Failure to do so not only impacts the current project but also has far-reaching consequences, including the ability to secure future government work. Currently, contractors report into the ICN (Industry Capability Network), which manages the submission process. Understanding the importance of proactive reporting and target achievement is essential for contractors seeking continued success in winning government contracts.</p>

    <p>Government agencies priorities contractors who demonstrate a track record of meeting reporting requirements and achieving contractual targets. These agencies rely on the ICN and other reporting mechanisms to evaluate the performance and capabilities of contractors. Contractors who consistently fall behind in reporting or fail to meet targets may face repetitional damage and jeopardise their chances of winning future government contracts. Demonstrating a strong commitment to timely and accurate reporting is crucial for maintaining a competitive edge.</p>
    
    <p>To ensure success in securing government contracts, contractors must adopt a proactive approach to reporting. This involves staying ahead of reporting deadlines, diligently tracking project progress, and providing comprehensive and accurate information to the ICN. By actively managing the reporting process, contractors can showcase their efficiency, reliability, and commitment to delivering projects in accordance with contractual requirements. This proactive reporting stance not only strengthens their reputation but also enhances their eligibility for future government contracts.</p>
    
    <p>Meeting contractual targets goes hand in hand with successful reporting. Contractors who consistently achieve targets demonstrate their ability to deliver projects on time, within budget, and to the required quality standards. This reliability and performance excellence are highly valued by government agencies when selecting contractors for future projects. Contractors that prioritise target achievement not only maintain a competitive advantage but also build a solid foundation for long-term success in securing government contracts.</p>
    
    <p>Contractors should view the ICN as a valuable partner in their reporting process. Leveraging the resources and expertise of the ICN can help streamline reporting, improve data accuracy, and enhance compliance. Regular communication with the ICN can provide contractors with valuable insights, ensuring they meet all reporting requirements and maintain a strong position for future government contracts.</p>
    
    <p>Staying ahead of reporting and meeting contractual targets is paramount for contractors aiming to secure future government work. By recognising the significance of proactive reporting and target achievement, contractors can maintain a competitive edge in the construction industry. Engaging with the ICN and adopting efficient reporting practices not only demonstrates reliability but also enhances the chances of winning government contracts. Embracing a proactive reporting approach is key to building a successful and sustainable future in government contracting within the construction industry.</p>`,
    title:
      "Staying Ahead of Reporting: The Key to Winning Government Contracts in Construction",
  },
  {
    author: "Taylor Jenkins",
    id: 4,
    img: BlogImages.Blog4Component,
    publish: "23 Aug 2023",
    text: `<p>As the demand for sustainable construction continues to grow, private developers are stepping up their commitment to environmental responsibility and social impact. CBus Property&rsquo;s $1 billion project, the 435 Bourke Street commercial office tower in Melbourne, exemplifies this paradigm shift. With a focus on achieving Net Zero Carbon, high green building ratings, and a Platinum WELL certification, the project embodies the fusion of sustainability and social consciousness in the construction industry.</p>

    <p>CBus Property is at the forefront of environmental stewardship, with a commitment to achieving Net Zero Carbon status by 2022. The 435 Bourke Street project is designed to operate as a Net Zero Carbon building, ensuring that its carbon emissions are offset through various strategies. The building&rsquo;s innovative solar skin design enables the generation of 20 percent of its base building electricity requirements on-site, leveraging renewable energy sources. The remaining energy needs will be met through off-site renewable electricity, further reducing the project&rsquo;s carbon footprint.</p>
    
    <p>435 Bourke Street also sets the bar high in terms of sustainable building certifications. The project aims to achieve a 6 Star Green Star New Buildings rating, a prestigious recognition of its outstanding environmental performance. This rating showcases the project&rsquo;s adherence to sustainable design, resource efficiency, and reduced environmental impact. Additionally, the project targets a Platinum WELL certification, emphasising its commitment to occupant health, well-being, and comfort. The integration of wellness features will create a space that promotes productivity, enhances the indoor environment, and prioritizes the health of its occupants.</p>
    
    <p>Beyond its commitment to environmental sustainability, CBus Property&rsquo;s 435 Bourke Street project demonstrates the growing emphasis on social impact in construction. By incorporating social procurement practices, the project seeks to support local businesses, create employment opportunities, and contribute to the local economy. Embracing sustainable construction practices with a social mind, CBus Property is setting an example for private developers to prioritize both environmental responsibility and community development in their projects.</p>
    
    <p>CBus Property&rsquo;s 435 Bourke Street project exemplifies the evolving landscape of sustainable construction with a social conscience. As private developers embrace ambitious sustainability goals, like achieving Net Zero Carbon and high green building ratings, they are also integrating social procurement practices to drive positive social impact. This project showcases the potential for private sector initiatives to lead the way in sustainable and socially responsible construction practices. By combining sustainability, wellness, and social procurement, CBus Property is shaping a future where the built environment creates positive outcomes for the environment, communities, and occupants alike.</p>`,
    title:
      "Combining Sustainability and Social Impact: CBus Property's 435 Bourke Street Project Sets New Standards",
  },
  {
    author: "Tony Barkey",
    id: 5,
    img: BlogImages.Blog5Component,
    publish: "08 Aug 2023",
    text: `<p>The New South Wales (NSW) government is taking a significant step forward in promoting social procurement by following in the footsteps of Victoria&rsquo;s groundbreaking initiative. Inspired by Victoria&rsquo;s success, NSW is introducing its own landmark Social Procurement framework. This framework aims to drive social impact through government procurement practices, aligning purchasing decisions with broader social and economic goals. By replicating Victoria&rsquo;s achievements, NSW is demonstrating a commitment to fostering socially conscious business practices and leveraging procurement as a catalyst for positive change.</p>

    <p>Victoria&rsquo;s Social Procurement framework has set a strong precedent, showcasing the transformative power of integrating social impact into government procurement. Recognizing the successes achieved in Victoria, NSW is determined to replicate and build upon this model. By adopting a similar framework, NSW can accelerate the integration of social enterprises into government supply chains, creating economic opportunities and supporting disadvantaged communities.</p>
    
    <p>Following Victoria&rsquo;s lead, NSW&rsquo;s Social Procurement framework opens up new avenues for social enterprises. By incorporating social impact considerations into procurement decisions, the government can actively support these enterprises and drive inclusive growth. This framework enables collaboration between the government and socially conscious businesses, ensuring that procurement practices align with sustainable and socially responsible principles.</p>
    
    <p>Victoria&rsquo;s Social Procurement framework has demonstrated the immense potential of prioritizing social impact in procurement decisions. NSW recognizes the importance of this approach and aims to set new standards for government procurement practices. By adopting a similar framework, NSW is proactively driving positive change, fostering an inclusive and equitable economy, and promoting sustainable business practices.</p>
    
    <p>Inspired by the achievements of Victoria&rsquo;s Social Procurement framework, NSW is poised to make a lasting impact. By integrating social value into procurement decisions, NSW aims to foster collaboration between businesses and the government, addressing social and environmental challenges together. Through the implementation of the Social Procurement framework, NSW envisions a future where businesses and government work hand in hand to create a more just and sustainable society.</p>
    
    <p>The introduction of NSW&rsquo;s Social Procurement framework marks an important milestone in the state&rsquo;s journey towards fostering socially conscious business practices. Inspired by Victoria&rsquo;s groundbreaking initiative, NSW is embracing the power of procurement to drive social impact and create economic opportunities for disadvantaged communities. By following Victoria&rsquo;s lead, NSW is positioning itself as a trailblazer in government procurement practices, setting new standards and leveraging the potential of businesses to create a more equitable and sustainable future. Through this landmark framework, NSW is paving the way for collaborative efforts that prioritize social responsibility and drive positive change.</p>`,

    title:
      "NSW Government Follows Victoria's Lead with Landmark Social Procurement Framework",
  },
  {
    author: "Tony Barkey",
    id: 6,
    img: BlogImages.Blog6Component,
    publish: "23 Jul 2023",
    text: `<p>Social procurement presents a unique opportunity to strengthen communities and generate social value through supply chain practices. With state governments and industries increasingly investing in social enterprises, Australia&rsquo;s infrastructure and construction projects are adopting social procurement frameworks. But what exactly is a social and sustainable procurement framework and policy, and why is it important for businesses?</p>
    <p>A social and sustainable procurement framework and policy empower businesses to make responsible purchasing decisions that benefit society, the environment, and the economy. By integrating social and environmental considerations into procurement practices, businesses can contribute to positive change while meeting government mandates for social procurement, local content, and local jobs policies.</p>
    <ul>
    <li>Key Components of a Social and Sustainable Procurement Framework:</li>
    <li>Stakeholder Engagement: Engaging with suppliers, customers, employees, and community members is vital to identify pressing social and environmental issues that procurement should address.</li>
    <li>Ethical Sourcing: Prioritizing ethical sourcing involves using fair-trade products, avoiding products associated with exploitation or human rights violations, and sourcing from environmentally responsible suppliers.</li>
    <li>Environmental Sustainability: Setting targets for waste reduction, resource conservation, and carbon footprint minimization promotes environmental sustainability within the procurement process.</li>
    <li>Social Impact: Aiming for positive social impact includes supporting local businesses and communities, creating employment opportunities, and promoting diversity and inclusion.</li>
    <li>Supply Chain Transparency: Requiring suppliers to provide transparent information about their practices, labour standards, environmental impact, and supply chain traceability ensures responsible sourcing.</li>
    <li>Evaluation and Reporting: Establishing evaluation systems and regular reporting tracks progress toward sustainability goals, demonstrating the business&rsquo;s commitment to accountability and transparency.</li>
    <li>Continuous Improvement: Regularly reviewing and updating the policy ensures it&rsquo;s alignment with emerging sustainability issues, stakeholder feedback, and changes in the business environment.</li>
    </ul>
    <p>Implementing a social and sustainable procurement framework and policy allows businesses to showcase their commitment to responsible practices and contribute to a more sustainable future. By considering social and environmental factors in procurement decisions, businesses have the power to positively impact communities, support ethical sourcing, reduce environmental footprint, and promote transparency across the supply chain. Embracing social and sustainable procurement not only meets government mandates but also aligns with the values of responsible business practices, fostering a more inclusive, resilient, and sustainable economy for all.</p>`,
    title:
      "Embracing Social & Sustainable Procurement: Building a Better Future",
  },
];

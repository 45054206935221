import { useEffect } from "react";
import hero from "assets/images/home/Analytics.png";
import arrowbtn from "assets/images/home/arrowBtn.png";
import { Footer } from "./Footer";
import { Header } from "./Header";
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const ReportingAndAnalytics = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section  */}
      <Header />
     {/* Hero Section  */}
    <div className="dark-blue-bg zig-hero-wrap bg-overlay">
        <div className="container-new">
          <div className="zig-hero-content hero-btns">
            <h4>Analytics & Insights</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
            Real-time project metrics
            </h3>
            <p>
            Gain valuable insights into project performance with real-time analytics across your supply chain, helping you make informed decisions and achieve your targets efficiently.</p>
            <a
              href="/request-demo"
              className="discover"
              style={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              }}
              >
              Request a demo
              <img src={arrowbtn} alt="arrowbtn" />
              </a>
           
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper">
         <div className="container-new">
           <div className="servicecards cardswrapper ">
            <div className="about-card">
            <BarChartIcon/>
              <h3>Data Driven Decision Making</h3>
              <p>
              SocialPro provides real-time data and insights, enabling informed decisions that optimise performance, address challenges, and ensure successful project outcomes efficiently.</p>
            </div>
            <div className="about-card">
            <DashboardCustomizeOutlinedIcon/>
             <h3>Dashboards Displaying Key Data</h3>
              <p>
              Intuitive dashboards display key project metrics, offering a comprehensive view of performance, simplifying monitoring, and enhancing transparency across the entire project with easily digestible visual data.
              </p>
            </div> 
            <div className="about-card">
            <TrendingUpOutlinedIcon/>
             <h3>In-depth Analysis to Uncover Trends</h3>
              <p>
              SocialPro provides in-depth analysis tools to understand the underlying drivers of project data, helping identify trends and optimise performance by revealing the drivers of outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

import { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Definitions = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const accordionData = [
    {
      content:
        "<p>Contribution in labour hours to a Project by females in trade, non-trade, and on-site managerial/supervisory and specialist roles under the Building Equality Policy (BEP) implemented by the Social Procurement Framework (SPF).</p>",
      id: 1,
      title: "Female Participation",
    },
    {
      content:
        "<p>An organisation, operating a business, that is 50% or more Indigenous-owned. It may take the form of a charity, incorporated association or trust. A social enterprise or registered charity may also be an Aboriginal-owned business if it is operating a business.</p>",
      id: 2,
      title: "Aboriginal-owned business",
    },
    {
      content: `<p>The Commonwealth has two targets for purchasing from Indigenous Enterprises:</p>
      <ul>
      <li>Wholly delivered in Australia; and </li>
      <li>Valued at $7.5 million (GST inclusive) or more; and</li> 
      <li>Where more than half of the value of the contract is being spent in one or more of nineteen specified industry sectors.</li>
      </ul>
      <p>The MMR requires suppliers to achieve a minimum percentage of Indigenous employment or supplier use (or a combination of both) on average over the term of the contract.</p>
      <p>The supplier, in consultation with the relevant purchasing Commonwealth entity, can elect to apply a target of 4 per cent Indigenous employment or supplier use at the contract level, or a target of 3 per cent at the organisational level. The supplier may elect to meet the MMR directly or through subcontracts. </p>
      `,
      id: 3,
      title: "Indigenous Participation",
    },
    {
      content: `<p>From 1 July 2020, relevant Commonwealth entities are required to include Mandatory Minimum Indigenous Participation Requirements (MMR) in the Approach to Market documentation for all procurement.</p>
      <ul>
      <li>Volume target - the equivalent of 3% of the number of eligible eligible procurements are awarded to Indigenous enterprises each financial year.</li>
      <li>Value target - the value of eligible procurements are awarded to Indigenous enterprises each financial year. From 1 July 2019, a series of progressively increasing value targets apply, up to a maximum value in 2027-2028, as per the below: 
        <ul>
          <li>2019-20     1% </li>
          <li>2020-21     1.25%</li>
          <li>2021-22     1.5%</li>
          <li>2022-23     1.75%</li>
          <li>2023-24     2%</li>
          <li>2024-25     2.25%</li>
          <li>2025-26     2.5%</li>
          <li>2026-27     2.75%</li>
          <li>2027-28     3% </li>         
        </ul>
      </li> 
      </ul>
      `,
      id: 4,
      title: "Mandatory Minimum Indigenous Participation Requirements",
    },
    {
      content: `<p>Proportion of content purchased for the Project that is produced by Victorian, Australian or New Zealand goods or services.</p>
      <p>The term local content covers all suppliers producing Australian or New Zealand (ANZ) goods or services or when they have added value to imported items. Local content means goods that are produced by local industry, or services that are supplied by local industry or construction activities carried out by local industry.</p> `,
      id: 5,
      title: " Local Content ",
    },
    {
      content: `<p>Proportion of workers on a project residing within 50km of the Project address.</p>
      <p>The Local Jobs First - Victorian Industry Participation Policy (VIPP) is an industry development policy that helps ensure competitive local businesses are provided with opportunities to supply into Victorian government projects.

      </p><p>The Local Jobs First - VIPP must be applied by all Victorian government departments and agencies for any type of project with a value of:
      Proportion of workers on a project residing within 50km of the Project address.
      The Local Jobs First - Victorian Industry Participation Policy (VIPP) is an industry development policy that helps ensure competitive local businesses are provided with opportunities to supply into Victorian government projects.
      The Local Jobs First - VIPP must be applied by all Victorian government departments and agencies for any type of project with a value of:
      $1 million or more in regional Victoria, or
      $3 million or more in metropolitan Melbourne or for state-wide activities.
      Minimum local content requirements are set for government projects worth $50 million or more or for other projects as agreed.
      Local Jobs First – VIPP helps ensure that local businesses are given full and fair opportunity to compete for Victorian government contracts.
      </p>`,
      id: 6,
      title: " Local Jobs ",
    },

    {
      content: `<p>Proportionate contribution of local apprentices, trainees and cadets to overall labour hours on a Project. 

      The Major Projects Skills Guarantee (MPSG) provides opportunities for apprentices, trainees and cadets to work on Victoria’s largest construction projects. This helps to ensure Victorians starting their career in the construction industry have more opportunities to receive on-the-job training.</p> 
      `,
      id: 7,
      title: "  MPSG Hours ",
    },

    {
      content: `<p>Value of goods, services and construction carried out by certified Social Enterprises or Australian Disability Enterprises under the Social Procurement Framework (SPF).
      Such expenditure is incurred to enterprises registered with BuyAbility’s online directory, or certified by Social Traders.
      </p> 
      <p>Integration of Social/Disability Enterprises into supply chains can achieve social and sustainable outcomes by contributing to training; employment and support for Victorians with disadvantage or disability, training and employment of long-term unemployed people, disengaged youth, single parents, migrants, refugees or workers in transition; use of environmentally sustainable materials and business practices; and sustainable regional economies and communities.</p>
      `,
      id: 8,
      title: "  Social/Disability Enterprise Spend",
    },

    {
      content: `<p>Contribution in labour hours to a Project by cohorts of disadvantaged Victorians under the Social Procurement Framework (SPF), namely:</p> 
      <ul>
      <li>Long-term unemployed people – people who have not been employed for 12 months or more excluding people undertaking studies </li>
      <li>Disengaged youth – people aged 15 to 24 years not studying and seeking full-time work</li>
      <li>Single parents – sole parents that are responsible for dependent or non-dependent children of any age (either living in the household or outside the household) and not sustainably employed for a period of 12 months or more</li>
      <li>Migrants – people who leave their country voluntarily to commence living in Australia and not in sustainable employment for period of 12 months or more</li>
      <li>Refugees – people subject to persecution in their home country and who now reside outside their home country and have resettled in Victoria, Australia and not sustainably employed for a period of 12 months or more</li>
      <li>Workers in transition – jobseekers who are recently retrenched or facing pending retrenchment due to business closure or industry transition, who require further training or on-the-job support to transition to new employment</li>
      </ul>
      <p> Social enterprises play an important role in providing transitional employment for disadvantaged job seekers as a pathway to employment in mainstream businesses.</p>

      <p> Social enterprises can also provide ongoing employment options for disadvantaged job seekers who may not be well placed to sustain mainstream employment over the longer term.
      </p>
      `,
      id: 9,
      title: "Opportunities for Disadvantaged Victorians ",
    },

    {
      content: `<p>Percentage of total demolition and construction waste that is disposed of by alternative 
      means to landfill, including through re-use, local remanufacturing or recycling.
      </p>
      `,
      id: 10,
      title: "Waste Diverted From Landfill ",
    },
    {
      content: `<p>  Measure of embodied carbon eliminated from construction through optimising design and 
      substituting materials, including using less materials in the actual building, such as reducing 
      slab thickness, and replacing concrete and steel with cross-laminated timber.
      </p>
      `,
      id: 11,
      title: "Reduce Concrete Embodied Carbon Emissions ",
    },
    {
      content: `<p>Volume of potable water and waste water generated through construction activities.</p>
      `,
      id: 12,
      title: "Minimise Water Consumption ",
    },

    {
      content: `<p>Percentage of steel used in construction that is recognised as “Green Steel”, that being, has 
      been produced by stripping oxygen from iron ore to produce pure iron metal using hydrogen 
      made from renewable energy rather than coal or natural gas.</p>
      `,
      id: 13,
      title: "Procure Green Steel",
    },

    {
      content: `<p>Green Star is a certification trademark registered by Green Building Council of Australia 
      (GBCA). There are four Green Star rating tools, these provide a means of certification for 
      building design and construction, communities, operational performance, and interior fitout.</p>
      `,
      id: 14,
      title: "Green Star Rating",
    },

    {
      content: `<p>Formal recognition of compliance with the standards outlined by the International 
      Organisation for Standardisation, which may address making a product, managing a process,
      delivering a service or supplying materials.</p>
      `,
      id: 15,
      title: "ISO Certification ",
    },

    {
      content: `<p>An entity’s business-wide policy for entitlements offered to employees who are experiencing 
      family and domestic violence to deal with the impacts of this violence where it is not 
      practical to do so outside their work hours. </p>
      <p>This might include making arrangements for their own or a family member’s safety 
      (including relocation), attending court or accessing police services, or attending counselling, 
      or appointments with medical, financial or legal professionals. </p>
      <p>Family and domestic violence refers to violent, threatening or other abusive behaviour that 
      seeks to coerce or control the employee and causes them harm or fear</p>
      `,
      id: 16,
      title: "Family/Domestic Violence Leave  Policy",
    },

    {
      content: `<p>An entity’s business-wide policy being a commitment to stakeholders around complying with
      legal obligations, minimising pollution, and applying a systematic environmental 
      management approach that is consistent with the international standard ISO 14001. A robust
      policy will address the following environmental management issues:</p>
      <ul>
      <li>Energy use</li>
      <li>Greenhouse gas emissions and ozone depleting substances</li>
      <li>Suppliers, produces and materials use</li>
      <li>Office waste, building waste, and resource recovery</li>
      <li>Potable water use and waste water</li>
      </ul>
      `,
      id: 17,
      title: "Environmental  Sustainability Policy ",
    },

    {
      content: `<p>An entity’s business-wide policy to plan, implement and measure change in order to achieve 
      gender equality in the workplace. The policy must include results of a workplace gender 
      audit to assess the current state of gender equality in the workplace, and strategies and 
      measures to improve gender equality in the workplace, based on the results of the audit.</p>
      `,
      id: 18,
      title: "Entity Level Gender Equality Action Plan ",
    },

    {
      content: `<p>A document outlining strategies to to support gender equality at the project level by:</p>
      <ul>
      <li>Understanding the importance of gender equality</li>
      <li>Identifying where change is needed</li>
      <li>Planning and implementing actions to achieve progress</li>
      <li>Measuring change over time.</li>
      </ul>
      <p>Different strategies apply at the project level compared to the organisational level, i.e. the 
      gender pay gap of an organisation and a project will differ and will require different targets 
      to eliminate or close the gap.</p>
      `,
      id: 19,
      title: " Project Level Gender Equality Action Plan",
    },
    {
      content: `<p>A Code introduced by the Victorian Government designed to promote labour standards and 
      encourage compliance with employment, industrial relations and workplace health and 
      safety obligations.</p>
      `,
      id: 20,
      title: "Fair Jobs Code ",
    },

    // Add more accordion data as needed
  ];

  return (
    <>
      {/* Header Section  */}
      <Header />

      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>Definitions</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            {" "}
            Industry Glossary
          </h2>
          <p>
            The following are brief definitions of the more notable social
            procurement and environmental sustainability terms.
          </p>
        </div>
      </div>

      {/* accordion */}
      <div className="accordion-section  ">
        <div className="container-new">
          <div
            className="accordion-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {accordionData.map((item) => (
              <Accordion
                key={item.id}
                defaultExpanded={item.id === 1}
                className="accordion-item"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordion-title"
                >
                  {item.title}
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                  {<div dangerouslySetInnerHTML={{ __html: item.content }} />}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};
